export const waitUntil = (func: () => Promise<boolean>) => {
  return new Promise<void>((resolve) => {
    const interval = setInterval(async () => {
      if (await func()) {
        clearInterval(interval);
        resolve();
      }
    }, 2_000);
  });
};
