import gearIcon from 'assets/images/nav_gear.svg';
import React, { useState } from 'react';
import { Offcanvas } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { MenuItem } from 'routes/routes.types';

export const GearIcon = (menuItems: MenuItem[]) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Offcanvas show={show} onHide={handleClose} placement="end">
        <Offcanvas.Header closeButton></Offcanvas.Header>
        <Offcanvas.Body>
          <hr />
          {menuItems.map((menuItem) => (
            <React.Fragment key={menuItem.title}>
              <Link to={menuItem.to} style={{ textDecoration: 'none' }} onClick={handleClose}>
                <div className="ps-5">{menuItem.title}</div>
              </Link>
              <hr />
            </React.Fragment>
          ))}
        </Offcanvas.Body>
      </Offcanvas>
      <img onClick={handleShow} src={gearIcon} alt=""></img>
    </>
  );
};
