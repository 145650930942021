import { useSpinner } from 'hooks/useSpinner';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { cotService } from 'services/cot.service';
import { GWSetupProgressBar, VGW_STEP_ITEMS } from '../components/GWSetupProgressBar/GWSetupProgressBar';
import { useRef, useState } from 'react';
import './GWSearchGwBySerial.css';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { routesConstant } from 'routes/routes.const';

export const GWSearchGwBySerial = () => {
  const showSpinner = useSpinner();
  const serialId = useRef('');
  const [hasError, setHasError] = useState(false);
  const navigate = useNavigate();
  const searchGW = async () => {
    showSpinner(true);
    try {
      console.log('the serial is', serialId);
      const parentGW = await cotService.getGwByChildSerial(serialId.current);
      navigate({
        pathname: routesConstant.gwSetupStart.relatirve({ deviceId: parentGW.parentDeviceId }),
        search: createSearchParams({ model: parentGW.model_name }).toString(),
      });
    } catch (error) {
      console.error(error);
      setHasError(true);
    } finally {
      showSpinner(false);
    }
  };
  return (
    <Form>
      <Container className="text-center">
        <div className="mb-4 mt-5">Installation wizard</div>
        <div className="setup-step-title mb-5">Enter Machine S/N</div>
        <Row>
          <Col>
            <Form.Group className="mb-2" controlId="clinicName">
              <Form.Control
                className={`setup-form-field-text ${hasError ? 'error-input' : ''}`}
                type="search"
                onChange={(e) => (serialId.current = e.target.value)}
              />
              <Form.Control.Feedback type="invalid">"invalid"</Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
        <div className="error-no-sn">
          {hasError ? (
            <>
              <div>This S/N doesn't exist.</div>
              <div>Check that the machine is online.</div>
            </>
          ) : (
            <></>
          )}
        </div>
        <GWSetupProgressBar theme="light" size="large" currentStep="Identification" inputSteps={VGW_STEP_ITEMS} />
        <div style={{ padding: '5vh' }}></div>
        <Button className="filled-btn ps-5 pe-5 pt-2 pb-2 mb-3" onClick={searchGW}>
          Start Wizard
        </Button>
      </Container>
    </Form>
  );
};
