import { AxiosError } from 'axios';

const COT_ERRORS: { [key: string]: string } = {
  'non_field_errors The fields name, company must make a unique set.':
    'The project name must be unique within a company.',
  'name This field may not be blank.': 'This field may not be blank.',
};

export function handleCotError(axiosError: AxiosError<any, any>, showPopup: (message: string) => void): boolean {
  if (process.env.REACT_APP_COT_BASE_API !== axiosError.config?.baseURL) {
    return false;
  }
  const data = axiosError.response?.data;
  const error = Object.keys(data)
    .filter((key) => Array.isArray(data[key]))
    .flatMap((key) => (data[key] as string[]).map((v) => key + ' ' + v))
    .map((error) => COT_ERRORS[error])
    .join(' ');

  if (error.trim() === '') {
    console.error(`not able to retrieve errors from ${data}`);
    return false;
  }
  showPopup(error);
  return true;
}
