import { useQuery } from '@tanstack/react-query';
import { cotService } from 'services/cot.service';
import { generateQueryKey } from './query.key.generator';

export const OPTIONAL_DEVICE_BY_ID_KEY = generateQueryKey();

export const useGetOptionalDeviceByIdQuery = (deviceId: string) => {
  return useQuery(
    [OPTIONAL_DEVICE_BY_ID_KEY, deviceId],
    async () => {
      const device = await cotService.getOptionalDeviceById(deviceId);
      return device ?? null;
    },
    { staleTime: 30_000 }
  );
};
