import { GWSetupProgressBar } from '../components/GWSetupProgressBar/GWSetupProgressBar';
import { EditClinic } from './EditClinic';

export const GWSetupSetLocation = () => {
  return (
    <>
      <GWSetupProgressBar currentStep={'Location'} />
      <EditClinic title="Set Gateway Location"></EditClinic>
    </>
  );
};
