import { useQuery } from '@tanstack/react-query';
import { generateQueryKey } from './query.key.generator';
import { useGetUserCompaniesQuery } from './useGetUserCompaniesQuery';
import { cotService } from 'services/cot.service';
import { CotProject } from 'types/cot.types';
import { Project, ProjectMetadata } from 'types/cot.properties.types';

export const FLAT_PROJECTS_KEY = generateQueryKey();

const createProject = async (cotProject: CotProject): Promise<Project> => {
  const metadata = await cotService.getProjectProperty<ProjectMetadata>(cotProject.company, cotProject.id, 'meta.data');
  return { metadata, ...cotProject };
};

const getFlatProjects = async (companyIds: string[]): Promise<Project[]> => {
  const projects = await Promise.all(companyIds.map((companyId: string) => cotService.getProjects(companyId)));
  return Promise.all(projects.flatMap((project) => project).map(async (cotProject) => await createProject(cotProject)));
};

export const useGetFlatProjectsQuery = () => {
  const { data: companies } = useGetUserCompaniesQuery();
  return useQuery(
    [FLAT_PROJECTS_KEY],
    async () => {
      const companyIds = companies!.map((c) => c.id);
      return await getFlatProjects(companyIds);
    },
    { enabled: !!companies, staleTime: 30_000 }
  );
};
