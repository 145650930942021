import { useState } from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { routesConstant } from 'routes/routes.const';

function HomeButtonCard({ title, bodyText }: { title: string; bodyText: string }) {
  const [isHovered, setIsHovered] = useState(false);
  const shadowClass = isHovered ? 'shadow' : 'shadow-sm';
  return (
    <Card className={'nb-submit-button h-100 ' + shadowClass}>
      <Card.Body onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
        <Card.Title className="nb-submit-button">{title}</Card.Title>
        <Card.Text className="nb-submit-button">{bodyText}</Card.Text>
      </Card.Body>
    </Card>
  );
}

export function HomePage() {
  const menu = [
    {
      title: 'Device allocation',
      bodyText: 'To allocate the device from the inventory to the spesific clinic',
      url: `/setup/${routesConstant.gwSearchGwBySerial.path()}`,
    },
    { title: 'Clinics', bodyText: 'Edit clinic page', url: routesConstant.clinicList.absolute() },
  ];
  return (
    <Container className="mt-2">
      <Row>
        {menu.map((m) => (
          <Col key={m.title} sm={12} md={6} className="mt-3">
            <Link className="mt-3 link-without-underline" to={m.url} style={{ textDecoration: 'none' }}>
              <HomeButtonCard bodyText={m.bodyText} title={m.title}></HomeButtonCard>
            </Link>
          </Col>
        ))}
      </Row>
    </Container>
  );
}
