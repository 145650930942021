import { useContext, createContext, useState, SetStateAction, Dispatch } from 'react';

type SelectPortsContextArgs = {
  setShowDescriptionModal: Dispatch<SetStateAction<boolean>>;
  showDescriptionModal: boolean;
  setMachineTypeClicked: Dispatch<SetStateAction<string>>;
  machineTypeClicked: string;
  showDescription: (machineType: string) => void;
};

const initialState: SelectPortsContextArgs = {
  setShowDescriptionModal: (value: SetStateAction<boolean>) => {},
  showDescriptionModal: false,
  setMachineTypeClicked: (value: SetStateAction<string>) => {},
  machineTypeClicked: '',
  showDescription: () => {},
};

const SelectPortsContext = createContext<SelectPortsContextArgs>(initialState);
export const useSelectPortsContext = () => {
  return useContext(SelectPortsContext);
};

export const SelectPortsProvider = ({ children }: { children: React.ReactNode }) => {
  const [showDescriptionModal, setShowDescriptionModal] = useState(false);
  const [machineTypeClicked, setMachineTypeClicked] = useState('');
  const args: SelectPortsContextArgs = {
    setShowDescriptionModal,
    showDescriptionModal,
    setMachineTypeClicked,
    machineTypeClicked,
    showDescription: (machineType) => {
      setShowDescriptionModal(true);
      setMachineTypeClicked(machineType);
    },
  };
  return <SelectPortsContext.Provider value={args}>{children}</SelectPortsContext.Provider>;
};
