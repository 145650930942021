import gwConnectionInstrIcon from 'assets/images/gw_connection_instructions.svg';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import { routesConstant } from 'routes/routes.const';
import { GWSetupProgressBar } from '../components/GWSetupProgressBar/GWSetupProgressBar';

export const GWSetupMachinesRecommendation = () => {
  const { companyId, projectId, deviceId } = useParams();
  return (
    <>
      <GWSetupProgressBar currentStep={'Machines'} />
      <Container>
        <Row>
          <Col className="mt-2 text-center">
            <img src={gwConnectionInstrIcon} alt=""></img>
          </Col>
        </Row>
        <Row>
          <Col className="setup-step-subtitle mb-4 fs-27 text-center">
            Before managing machines, we recommend connecting all the clinic's machines to the gateway first
          </Col>
        </Row>
        <Row>
          <Col className="setup-step-subtitle mb-4 text-center">
            Having all the devices connected to the gateway before configuring it will help you see all is working and
            online. You can see the devices added on the gateway screen as you go.
          </Col>
        </Row>
        <Row className="mt-4 mb-5 text-center">
          <Col>
            <Link
              to={routesConstant.gwSetupLocationDetails.relatirve({
                deviceId: deviceId!,
                companyId: companyId!,
                projectId: projectId!,
              })}
              style={{ textDecoration: 'none' }}
            >
              <Button className="outlined-btn me-3">Back</Button>
            </Link>
            <Link
              to={routesConstant.gwSetupSetMachines.relatirve({
                deviceId: deviceId!,
                companyId: companyId!,
                projectId: projectId!,
              })}
              style={{ textDecoration: 'none' }}
            >
              <Button className="filled-btn">Continue</Button>
            </Link>
          </Col>
        </Row>
      </Container>
    </>
  );
};
