import { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useAppSelector } from '../hooks/useApp';
import { selectSpinnerState } from '../store/slices/spinnerSlice';

export const SpinnerLayout = () => {
  const spinnerState = useAppSelector(selectSpinnerState);
  const [show, setShow] = useState<boolean>(spinnerState.show);
  useEffect(() => {
    setShow(spinnerState.show);
  }, [spinnerState.show]);

  return (
    <>
      {show && spinnerState.show && (
        <Modal show={show} fullscreen={true} className="opacity-50" onExited={() => setShow(spinnerState.show)}>
          <div className="d-flex justify-content-center">
            <div
              id="myspinner"
              className="spinner-border text-primary"
              style={{ marginTop: '9rem', width: '9rem', height: '9rem' }}
              role="status"
            />
          </div>
        </Modal>
      )}
    </>
  );
};
