import { useAsync } from 'hooks/useAsync';
import './ClinicViewIframe.css';
import { gwService } from 'services/gw.service';
import { cotService } from 'services/cot.service';
import { useAppSelector } from '../../hooks/useApp';
import { UserState, selectUser } from '../../store/slices/userSlice';
import { ProjectConfig } from '../../types/cot.properties.types';
import { CotDevice, CotProject } from 'types/cot.types';
import { CLINIC_DEFAULT_URL } from 'constants/project.config.constants';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useShowPopup } from 'hooks/useShowPopup';
import { routesConstant } from 'routes/routes.const';

const getDeviceProject = async (user: UserState): Promise<CotProject | undefined> => {
  const deviceInformation = await gwService.getDeviceInformation();
  const device = await cotService.getOptionalDeviceById(deviceInformation.deviceId);
  const userProfile = await cotService.getUserProfile(user.company);
  const projects = await cotService.getProjectsRecursive(user.company, userProfile);
  return projects.find((project) => project.id === device?.project);
};

const getProjectConfig = async (user: UserState): Promise<ProjectConfig | undefined> => {
  const project = await getDeviceProject(user);
  if (!project) {
    return undefined;
  }
  return await cotService.getProjectProperty<ProjectConfig>(project.company, project.id, 'project.config');
};

const getClinicUrl = async (user: UserState): Promise<string | unknown> => {
  const projectConfig = await getProjectConfig(user);
  return projectConfig?.clinicUrl ?? CLINIC_DEFAULT_URL;
};

const isDeviceBelongToTheUser = async (): Promise<boolean> => {
  return (await getDevice()) !== undefined;
};

const getDevice = async (): Promise<CotDevice | undefined> => {
  const deviceInformation = await gwService.getDeviceInformation();
  const deviceId = deviceInformation.deviceId;
  return await cotService.getOptionalDeviceById(deviceId);
};

const useRedirect = (isDeviceExists: boolean | undefined) => {
  const navigate = useNavigate();
  const showPopup = useShowPopup();
  useEffect(() => {
    if (isDeviceExists === undefined || isDeviceExists) {
      return;
    }
    navigate(routesConstant.allocateQrCode.absolute());
    showPopup('The device is not belong to the user. Cannot see the clinic.');
  }, [isDeviceExists, showPopup, navigate]);
};

export const ClinicViewIframe = () => {
  const user = useAppSelector(selectUser);
  const { value: device } = useAsync(() => getDevice());
  const { value: clinicUrl } = useAsync(() => getClinicUrl(user));
  const { value: isDeviceExists } = useAsync(() => isDeviceBelongToTheUser(), {
    interval: 30000,
  });
  useRedirect(isDeviceExists);
  if ([clinicUrl, isDeviceExists, device].includes(undefined)) {
    return <></>;
  }
  return (
    <div className="container-iframe">
      <iframe
        className="responsive-iframe"
        title="ClinicViewIframe"
        src={`${clinicUrl}?key=${user.apiKey}&company=${user.company}&project=${device?.project}`}
      ></iframe>
    </div>
  );
};
