import { Image, Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

type OperatorGuideProps = {
  qrUrl: string;
  description: string;
};

export const OperatorGuide = (props: OperatorGuideProps) => {
  const { t } = useTranslation();

  return (
    <>
      <Container className="mt-5">
        <p>{t(props.description)}</p>
        <Image src={props.qrUrl} />
      </Container>
    </>
  );
};
