import { NOT_INITILIZED } from 'constants/string.constants';
import { useAppSelector } from 'hooks/useApp';
import { useEffect, useState } from 'react';
import { routesConstant } from 'routes/routes.const';
import { cotService } from 'services/cot.service';
import { gwService } from 'services/gw.service';
import { getConfig, selectUser } from 'store';
import { isInventoryProject } from 'utils/project.utils';

const getBoardStartRoute = async () => {
  const deviceInformation = await gwService.getDeviceInformation();
  const deviceId = deviceInformation.deviceId;
  const device = await cotService.getOptionalDeviceById(deviceId);
  if (!device) {
    return routesConstant.allocateQrCode.path();
  }
  return isInventoryProject(device.deviceProject)
    ? routesConstant.allocateQrCode.path()
    : routesConstant.clinicViewIframe.path();
};

export const useBoardStartRoute = (): string | undefined => {
  const config = useAppSelector(getConfig);
  const user = useAppSelector(selectUser);
  const [boardStartRoute, setBoardStartRoute] = useState<string>();
  useEffect(() => {
    if (NOT_INITILIZED === user.email) {
      return;
    }
    if (!config.isGwDevice || '' === user.email) {
      setBoardStartRoute(routesConstant.allocateQrCode.path());
      return;
    }
    const controller = new AbortController();
    getBoardStartRoute().then((path) => {
      if (controller.signal.aborted) {
        return;
      }
      setBoardStartRoute(path);
    });
    return () => controller.abort();
  }, [user, config.isGwDevice, boardStartRoute]);
  return boardStartRoute;
};
