import './GWSetupProgressBar.css';
import { useSearchParams } from 'react-router-dom';

export type StepperItem = {
  name: string;
};

interface ProgressBarProps {
  theme?: 'light' | 'dark';
  size?: 'normal' | 'large';
  currentStep?: string;
  completedStep?: string;
  allCompleted?: boolean;
  inputSteps?: StepperItem[];
}

export const VGW_STEP_ITEMS: StepperItem[] = [
  {
    name: 'Identification',
  },
  {
    name: 'Clinic',
  },
  {
    name: 'Location',
  },
];

export const GW_STEP_ITEMS: StepperItem[] = [
  ...VGW_STEP_ITEMS,
  {
    name: 'Machines',
  },
];

const useSteps = (inputSteps?: StepperItem[]): StepperItem[] => {
  const [search] = useSearchParams();
  if (inputSteps) {
    return inputSteps;
  }
  const model = search.get('model');
  if (model) {
    return VGW_STEP_ITEMS;
  }
  return GW_STEP_ITEMS;
};

export const GWSetupProgressBar: React.FC<ProgressBarProps> = ({
  theme = 'dark',
  size = 'normal',
  currentStep,
  completedStep,
  allCompleted = false,
  inputSteps,
}: ProgressBarProps) => {
  const steps = useSteps(inputSteps);

  const getClassName = (stepName: string) => {
    const classes: string[] = [theme, size];
    const stepNames = steps.map((step) => step.name);
    if (currentStep === stepName) {
      classes.push('current-stage');
    }

    const isCurrentStepNotPasst = currentStep && stepNames.indexOf(currentStep) > stepNames.indexOf(stepName);
    if (isCurrentStepNotPasst || stepName === completedStep || allCompleted) {
      classes.push('completed-stage');
    }

    return classes.join(' ');
  };

  const getStepWidth = () => {
    return steps && steps.length > 0 ? `${Math.floor(100 / steps.length)}%` : '100%';
  };

  return (
    <div className={`pb-container ${theme} ${size}`}>
      <ul className={`progressbar ${theme} ${size}`}>
        {steps?.map((stepperItem, index) => (
          <li
            key={stepperItem.name}
            className={getClassName(stepperItem.name)}
            step-ind={index + 1}
            style={{ width: getStepWidth() }}
          >
            {stepperItem.name}
          </li>
        ))}
      </ul>
    </div>
  );
};
