import { useShowKeyboard } from 'hooks/useShowKeyboard';
import { useShowPopup } from 'hooks/useShowPopup';
import { useSpinner } from 'hooks/useSpinner';
import { useEffect, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { API_MAP, request } from 'services/httpService';
import { useWifiContext } from './WifiContext';
import styles from './wifi.module.scss';
import classnames from 'classnames';
import { InputPasswordWithEye } from '../InputPasswordWithEye/InputPasswordWithEye.component';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../hooks/useApp';
import { selectPopupState } from '../../store';
import { WifiSvg } from 'components/svg.components/WifiSvg';

export const WifiConnectModal = () => {
  const [password, setPassword] = useState('');
  const [connectActionSucceeded, setConnectActionSucceeded] = useState(false);
  const wifiContext = useWifiContext();
  const showKeyboard = useShowKeyboard();
  const showSpinner = useSpinner();
  const showPopup = useShowPopup();
  const location = useLocation();
  const navigate = useNavigate();
  const popupState = useAppSelector(selectPopupState);

  const hideWifiConnectModal = () => {
    wifiContext?.setShowWifiConnectModal(false);
  };

  useEffect(() => {
    if (wifiContext.showWifiConnectModal) setPassword('');
  }, [wifiContext.showWifiConnectModal]);

  useEffect(() => {
    if (connectActionSucceeded && !popupState.show && location.state?.from) navigate(location.state.from);
  }, [popupState.show, connectActionSucceeded, location.state, navigate]);

  const connectToWifi = async () => {
    try {
      hideWifiConnectModal();
      showSpinner(true);
      await request(API_MAP.connectToWifi, { payload: { ...wifiContext.wifiDetails, password } });
      showPopup('Connected successfully');
      setConnectActionSucceeded(true);
    } catch (error) {
      showPopup('Failed to connect');
    } finally {
      showSpinner(false);
    }
  };

  return (
    <>
      <Modal show={wifiContext.showWifiConnectModal}>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <WifiSvg signalStrength={wifiContext.wifiDetails.signal} theme="dark" size="medium" />
              <Form.Label className={classnames('px-3', styles.ssidTitle)}>{wifiContext.wifiDetails.ssid}</Form.Label>
            </Form.Group>
            <Form.Label className={classnames(styles.passwordTitle)}>Password</Form.Label>
            <Form.Group className="mb-3 d-flex">
              <InputPasswordWithEye
                placeholder="Password"
                value={password}
                onClick={(e) => {
                  showKeyboard({ show: true, input: '', setInput: setPassword, isPassword: true });
                }}
                style={{ width: '90%' }}
              />
            </Form.Group>
            <Form.Group className="my-2 text-center">
              <Button className={classnames(styles.wifiButton, styles.wifiButtonWidth200)} onClick={connectToWifi}>
                Connect
              </Button>
              <Button
                className={classnames(styles.wifiButton, styles.wifiButtonWidth200)}
                onClick={hideWifiConnectModal}
              >
                Cancel
              </Button>
            </Form.Group>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};
