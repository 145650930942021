import { useState, useCallback, useEffect } from 'react';

type UseAsyncOptions = {
  immediate: boolean;
  interval: number;
};

const useAsyncOptionsDefaultValues: UseAsyncOptions = {
  immediate: true,
  interval: 0,
};

export const useAsync = <Type>(asyncFunction: () => Promise<Type>, options?: Partial<UseAsyncOptions>) => {
  const { immediate, interval } = { ...useAsyncOptionsDefaultValues, ...options };
  const [status, setStatus] = useState<'idle' | 'pending' | 'success' | 'error'>(() =>
    immediate ? 'pending' : 'idle'
  );
  const [value, setValue] = useState<Type | undefined>(undefined);
  const [error, setError] = useState<any>(undefined);

  const execute = useCallback(async () => {
    setStatus('pending');
    try {
      const response = await asyncFunction();
      setValue(response);
      setStatus('success');
    } catch (error) {
      setError(error);
      setStatus('error');
    }
  }, []);

  useEffect(() => {
    if (immediate) {
      execute();
    }
    let interalId: NodeJS.Timer;
    if (interval > 0) {
      interalId = setInterval(() => execute(), interval);
    }
    return () => clearInterval(interalId);
  }, [execute, immediate, interval]);

  return { execute, status, value, error };
};
