import { WifiDetails } from 'components/WifiSelectModal/model/WifiDetails';

export const wifiList = (): Array<WifiDetails> => {
  const wifis: Array<WifiDetails> = [];
  for (let i = 0; i < 24; i++) {
    wifis.push({
      connected: i === 0,
      signal: ((i + 1) * 24) / 100,
      ssid: 'WIFI_' + Math.random(),
    });
  }
  return wifis;
};
