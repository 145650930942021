import { ChangeEventHandler, CSSProperties, LegacyRef, MouseEventHandler, useState } from 'react';
import { ReactComponent as EyeIcon } from '../../assets/images/eye.svg';
import styles from './inputPasswordWithEye.component.module.css';

interface InputPasswordWithEyeProps {
  placeholder?: string;
  inputRef?: LegacyRef<HTMLInputElement>;
  value: string;
  onClick?: MouseEventHandler<HTMLInputElement>;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  style?: CSSProperties;
  id?: string;
  className?: string;
  inputClassName?: string;
  svgClassName?: string;
}
export const InputPasswordWithEye = ({
  placeholder,
  style,
  inputRef,
  value,
  onClick,
  onChange,
  id,
  className,
  inputClassName,
  svgClassName,
}: InputPasswordWithEyeProps) => {
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordView = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div
      className={className ? className : ''}
      style={{ border: '1.5px solid var(--gray)', borderRadius: '0.275rem', width: '100%' }}
    >
      <input
        id={id ? id : 'id_InputPasswordWithEye'}
        className={styles.inputPasswordField + (inputClassName ? ' ' + inputClassName : '')}
        type={showPassword ? 'text' : 'password'}
        placeholder={placeholder}
        style={{ ...style, border: 'none', outline: 'none' }}
        ref={inputRef}
        value={value}
        onClick={onClick}
        onChange={onChange ? onChange : () => {}}
      />
      <EyeIcon
        className={'my-auto py-1' + svgClassName ? ' ' + svgClassName : ''}
        onClick={togglePasswordView}
        style={{ width: '10%' }}
      />
    </div>
  );
};
