import { Col, Form, Row } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './floatingInput.scss';
import classNames from 'classnames';
import { FloatingSelectInputProps } from './FloatingProps';

const FloatingSelectInput = ({ label, className, value, options, onChange, validate }: FloatingSelectInputProps) => {
  const [typed, setTyped] = useState(Boolean(value));
  const [selectedValue, setSelectedValue] = useState(value || '');
  const [error, setError] = useState(false);

  useEffect(() => {
    setSelectedValue(value || '');
    setTyped(Boolean(value));
  }, [value]);

  const handleFocus = () => {
    setTyped(true);
  };

  const handleBlur = () => {
    if (!selectedValue) {
      setTyped(false);
    }

    if (validate && !validate(selectedValue)) {
      setError(true);
    } else {
      setError(false);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedVal = e.target.value;
    setSelectedValue(selectedVal);

    if (onChange) {
      onChange(selectedVal);
    }
  };

  return (
    <Row>
      <Col>
        <Form.Group className={classNames('floating-select-group', className || '')}>
          <Form.Select
            value={selectedValue}
            onFocus={handleFocus}
            onBlur={handleBlur}
            onChange={handleChange}
            className={error ? 'error-field' : ''}
          >
            <option value="" disabled hidden></option>
            {options.map((option, index) => (
              <option key={index} value={option}>
                {option}
              </option>
            ))}
          </Form.Select>
          <Form.Label className={classNames({ active: typed }, { 'has-value': selectedValue })}>{label}</Form.Label>
          {error && <span className="error-message">Invalid input</span>}
        </Form.Group>
      </Col>
    </Row>
  );
};

export default FloatingSelectInput;
