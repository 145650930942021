import { useAppDispatch } from './useApp';
import { setConfig } from '../store';
import { useEffect } from 'react';

export const useSetConfig = (isGwDevice: boolean) => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (!isGwDevice) {
      return;
    }
    dispatch(setConfig({ isGwDevice }));
  }, [dispatch, isGwDevice]);
};
