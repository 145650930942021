import React, { useState } from 'react';
import styles from './serial.machines.module.scss';
import { Button, Modal, ModalBody } from 'react-bootstrap';
import { cotService } from 'services/cot.service';
import { GET_SERIAL_PORT_CONFIGS } from 'constants/queryKeys';
import { useSelectPortsContext } from './SelectPortsContext';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { useSpinner } from 'hooks/useSpinner';

function EnterSerialPopup(props: { serialPort: any; show: boolean }) {
  const [serialNumberValue, setSerialNumberValue] = useState('');
  const showSpinner = useSpinner();
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSerialNumberValue(event.target.value);
  };
  const { deviceId } = useParams();
  const { data: serialPortConfigs } = useQuery([GET_SERIAL_PORT_CONFIGS], () =>
    cotService.getSerialPortConfigs(deviceId!)
  );
  const queryClient = useQueryClient();
  const context = useSelectPortsContext();

  const setSerialNumber = async (serial: string) => {
    const serialPortConfig = serialPortConfigs?.find((config) => config.portName === props.serialPort);
    if (serialPortConfig) {
      serialPortConfig.serial = serial;
      await cotService.setSerialPortConfigs(serialPortConfigs!, deviceId!);
      queryClient.invalidateQueries([GET_SERIAL_PORT_CONFIGS]);
      context.showDescription('Atos');
      setSerialNumberValue('');
    }
  };

  const handleConfirm = async () => {
    try {
      showSpinner(true);
      await setSerialNumber(serialNumberValue);
    } finally {
      showSpinner(false);
    }
  };

  return (
    <Modal show={props.show} className={styles.enterSerialPopup}>
      <ModalBody className={styles.enterSerialContent}>
        <h2>{'Enter Serial Number:'}</h2>
        <input className={styles.inputStyle} type="text" value={serialNumberValue} onChange={handleChange} />
        <div className={styles.promptButtons}>
          <Button className="filled-btn ps-5 pe-5 pt-2 pb-2 mb-3" onClick={handleConfirm}>
            OK
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
}

export default EnterSerialPopup;
