import axios from 'axios';
import MockAdapter from 'axios-mock-adapter';
import { generatePath, Params } from 'react-router-dom';
import { ApiMapItem, CallbackResponseSpecFunc } from './types/request.types';

const generateCallBackResponseFunc = (response: readonly [status: number, params: Object]) => {
  const func = () => [...response];
  return func as CallbackResponseSpecFunc;
};

const axiosBaseInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_API,
  withCredentials: false,
});

const axiosCOTInstance = axios.create({
  baseURL: process.env.REACT_APP_COT_BASE_API,
  withCredentials: false,
});

const axiosTuttInstance = axios.create({
  baseURL: process.env.REACT_APP_TUTT_BASE_API,
  withCredentials: false,
});

const axiosApiTuttInstance = axios.create({
  baseURL: process.env.REACT_APP_TUTT_API,
  withCredentials: false,
});

const getAxiosInstance = (apiMapRequest: ApiMapItem) => {
  const instances = {
    base: axiosBaseInstance,
    cot: axiosCOTInstance,
    tutt: axiosTuttInstance,
    apitutt: axiosApiTuttInstance,
  };
  return (instances as any)[apiMapRequest.axiosInstance];
};

const getAxiosMockInstance = (apiMapRequest: ApiMapItem) => {
  return new MockAdapter(getAxiosInstance(apiMapRequest), { delayResponse: 1000 });
};

const getMockResponse = (apiMapRequest: ApiMapItem) => {
  return typeof apiMapRequest.mock === 'function'
    ? apiMapRequest.mock
    : generateCallBackResponseFunc(apiMapRequest.mock);
};

const mockRequest = (apiMapRequest: ApiMapItem, path: string) => {
  const mockResponse = getMockResponse(apiMapRequest);
  const mockAdapter = getAxiosMockInstance(apiMapRequest);
  switch (apiMapRequest.method) {
    case 'get':
      mockAdapter.onGet(path).reply(mockResponse);
      break;
    case 'post':
      mockAdapter.onPost(path).reply(mockResponse);
      break;
  }
};

export const getXApiKeyHeaders = (apiKey: string) => ({ 'x-api-key': apiKey });

export const getApiTuttHeaders = ({ apiKey, company }: { apiKey: string; company: string }) => ({
  'cot-user-company': company,
  'cot-x-api': apiKey,
});

export const request = async (
  apiMapRequest: ApiMapItem,
  options?: { urlParams?: Params; payload?: any; headers?: any }
) => {
  const path = generatePath(apiMapRequest.url, options?.urlParams);

  if (apiMapRequest.useMock && apiMapRequest.mock && process.env.NODE_ENV === 'development') {
    mockRequest(apiMapRequest, path);
  }

  try {
    const axiosInstance = getAxiosInstance(apiMapRequest);
    const res = await axiosInstance.request({
      method: apiMapRequest.method,
      url: path,
      data: options?.payload,
      headers: options?.headers,
    });
    return res?.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
