import { useAppDispatch } from './useApp';
import { showSpinner } from '../store/slices/spinnerSlice';
import { useEffect } from 'react';

export const useSpinnerEffect = (isShowSpinner: boolean) => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(showSpinner(isShowSpinner));
  }, [isShowSpinner, dispatch]);
};

export const useSpinner = (): ((isShowSpinner: boolean) => void) => {
  const dispatch = useAppDispatch();
  return (isShowSpinner: boolean) => {
    dispatch(showSpinner(isShowSpinner));
  };
};

export function useSpinnerDecorator<T extends any[], R>(fn: (...args: T) => Promise<R>) {
  const showSpinner = useSpinner();
  return async (...args: T) => {
    try {
      showSpinner(true);
      return await fn(...args);
    } finally {
      showSpinner(false);
    }
  };
}
