import { createSearchParams, useNavigate, useSearchParams } from 'react-router-dom';

export const useNavigateWithSearchParams = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  return (pathname: string) =>
    navigate({
      pathname,
      search: createSearchParams(searchParams).toString(),
    });
};
