import { useQuery } from '@tanstack/react-query';
import { cotService } from 'services/cot.service';
import { generateQueryKey } from './query.key.generator';
import { useAppSelector } from 'hooks/useApp';
import { selectUser } from 'store';

export const GET_USER_COMPANIES_KEY = generateQueryKey();

export const useGetUserCompaniesQuery = () => {
  const user = useAppSelector(selectUser);
  return useQuery(
    [GET_USER_COMPANIES_KEY, user.company],
    async () => {
      return await cotService.getUserCompanies(user.company);
    },
    { staleTime: 30_000 }
  );
};
