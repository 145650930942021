import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';

export interface KeyboardState {
  show: boolean;
  input: string;
  setInput: (value: string) => void;
  isPassword?: boolean;
  defaultLayout?: string;
}

export const initialState: KeyboardState = {
  show: false,
  input: '',
  setInput: (value) => {},
};

export const keyboardSlice = createSlice({
  initialState,
  name: 'Keyboard',
  reducers: {
    showKeyboard: (state, action: PayloadAction<KeyboardState>) => {
      return action.payload;
    },
  },
});

export const { showKeyboard } = keyboardSlice.actions;

export const selectKeyboardState = (state: RootState) => state.keyboard;

export default keyboardSlice.reducer;
