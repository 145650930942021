import { useAsync } from 'hooks/useAsync';
import { Alert } from 'react-bootstrap';
import { cotService } from 'services/cot.service';
import { SYS_ONLINE } from 'types/cot.stream.constants';

export const OfflineAlert = ({ projectId, deviceId }: { projectId: string; deviceId: string }) => {
  const { value: sysOnlineStream } = useAsync(
    () => cotService.getDeviceStream<number>(projectId!, deviceId!, SYS_ONLINE),
    { interval: 5000 }
  );

  if (sysOnlineStream?.value === 1) {
    return <></>;
  }

  return (
    <Alert variant={'danger'}>
      This gateway is offline, please make sure that the gateway is ON and connected to the WiFi network
    </Alert>
  );
};
