import { Col, Row, Form, InputGroup } from 'react-bootstrap';
import { FieldValues, UseFormRegister } from 'react-hook-form';
import { ProjectContact } from 'types/cot.properties.types';
import { ErrorMessage } from '@hookform/error-message';
import * as Yup from 'yup';

interface ContactPersonProps {
  contact: ProjectContact;
  register: UseFormRegister<FieldValues>;
  errors: any;
  errClassName: string;
}
export const ContactPerson = ({ contact, register, errors, errClassName }: ContactPersonProps) => {
  const handleEmailValidation = async (email: string) => {
    return await Yup.string().email().isValid(email);
  };
  return (
    <>
      <Row>
        <Col className="setup-section-title mt-4">Contact Person</Col>
      </Row>
      <Row>
        <Col className="setup-comment mb-2">
          We will use this info to create a CoT user, please make sure it's accurate.
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group className="mb-2" controlId="contactName">
            <Form.Control
              className={'setup-form-field-text ' + (errors.contactName ? errClassName : '')}
              type="text"
              placeholder="Name*"
              defaultValue={contact.name}
              {...register('contactName', {
                required: true,
                onChange: (e) => {
                  contact.name = e.target.value;
                },
              })}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group className="mb-2" controlId="contactEmail">
            <Form.Control
              className={'setup-form-field-text ' + (errors.contactEmail ? errClassName : '')}
              type="text"
              placeholder="Email*"
              {...register('contactEmail', {
                required: true,
                validate: handleEmailValidation,
                onChange: (e) => {
                  contact.email = e.target.value;
                },
              })}
              defaultValue={contact.email}
            />
            {errors.contactEmail && contact.email && (
              <ErrorMessage
                errors={errors}
                name="contactEmail"
                render={() => <p className="error-text">Email is invalid</p>}
              />
            )}
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group className="mb-2" controlId="contactTitle">
            <Form.Control
              className="setup-form-field-text"
              type="text"
              placeholder="Title"
              onChange={(e) => (contact.position = e.target.value)}
              defaultValue={contact.position}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group controlId="phone">
            <InputGroup>
              <Form.Control
                className="setup-form-field-text"
                type="text"
                placeholder="Phone"
                onChange={(e) => (contact.phone = e.target.value)}
                defaultValue={contact.phone}
              />
            </InputGroup>
          </Form.Group>
        </Col>
      </Row>
    </>
  );
};
