import './InputText.css';

type InputTextProps = {
  value?: string;
  icon?: string;
  placeholder?: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  inputRef?: any;
  id?: string;
  style?: React.CSSProperties;
};

export const InputText = ({ value, icon, placeholder, onChange, inputRef, id, style = {} }: InputTextProps) => {
  return (
    <div className="input_wrapper" style={style}>
      <input
        style={{ background: icon }}
        className="input"
        onChange={onChange}
        value={value}
        placeholder={placeholder}
        ref={inputRef}
        id={id ? id : 'id_inputText'}
      />
      {icon ? <img className="icon" src={icon} alt=""></img> : <></>}
    </div>
  );
};
