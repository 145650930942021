import { ProjectAddress } from "types/cot.properties.types";

export const concatAddressLines = (line1: string, line2: string): string => {
  return line1 + ', ' + line2;
};

export const concatAddress = (projectAddress?: ProjectAddress): string => {
  if (!projectAddress) {
    return '';
  }
  return projectAddress.line1 + ', ' + projectAddress.line2;
};
