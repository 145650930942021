import { useQuery } from '@tanstack/react-query';
import threeDots from 'assets/images/three_dots.svg';
import { GET_SERIAL_PORT_CONFIGS } from 'constants/queryKeys';
import { useAsync } from 'hooks/useAsync';
import { useRef, useState } from 'react';
import { Button, Col, Overlay, Popover, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { cotService } from 'services/cot.service';
import { SerialPortConfig } from 'types/cot.types';
import { SerialMachineRemoveReplace } from './SerialMachineRemoveReplace';
import styles from './serial.machines.module.scss';
import { SerialDropDownMachineList } from './SerialDropDownMachineList';
import EnterSerialPopup from './EnterSerialPopup';
import { SelectPortsProvider } from './SelectPortsContext';

const SerialMachineSelector = ({
  serialPort,
  serialPortConfig,
  showConfig,
}: {
  serialPort: string;
  serialPortConfig?: SerialPortConfig;
  showConfig: () => void;
}) => {
  const [showRemoveReplace, setShowRemoveReplace] = useState(false);
  const target = useRef(null);

  if (!serialPortConfig) {
    return (
      <>
        <Row className="h-70 mb-2">
          <Col className="d-flex align-items-center">
            <Button
              className="outlined-btn flex-grow-1"
              onClick={() => {
                showConfig();
              }}
            >
              Assign {serialPort}
            </Button>
          </Col>
        </Row>
      </>
    );
  }
  return (
    <>
      <Row className="h-70 mb-2">
        <Col className="d-flex align-items-center">
          <div className="machine-icon"></div>
          <div className="d-flex flex-column flex-grow-1">
            <div className="setup-section-title">{serialPortConfig.machineType}</div>
            <div className="setup-section-subtitle fs-14"></div>
          </div>
          <div className="d-flex">
            <div className="fs-21">{serialPortConfig.portName}</div>
            <div>
              <Button variant="link" ref={target} onClick={() => setShowRemoveReplace(!showRemoveReplace)}>
                <img alt="action" src={threeDots} />
              </Button>
            </div>
          </div>
        </Col>
      </Row>
      <Overlay
        rootClose={true}
        rootCloseEvent={'click'}
        onHide={() => setShowRemoveReplace(!showRemoveReplace)}
        target={target.current}
        show={showRemoveReplace}
        placement="left"
        container={target.current}
      >
        {(props) => (
          <Popover className={styles.removeReplace} {...props}>
            <Popover.Body>
              <SerialMachineRemoveReplace port={serialPort} clicked={() => setShowRemoveReplace(!showRemoveReplace)} />
            </Popover.Body>
          </Popover>
        )}
      </Overlay>
    </>
  );
};

const showSetSerial = (serialPortConfigs?: SerialPortConfig[], serialPort?: string) => {
  const serialPortConfig = serialPortConfigs?.find((machine) => machine.portName === serialPort);
  if (!serialPortConfig) {
    return false;
  }
  if (serialPortConfig.machineType !== 'Atos') {
    return false;
  }
  return serialPortConfig.serial === undefined;
};

const PortSelector = (props: { serialPort: string }) => {
  const { deviceId } = useParams();
  const { data: serialPortConfigs } = useQuery([GET_SERIAL_PORT_CONFIGS], () =>
    cotService.getSerialPortConfigs(deviceId!)
  );
  const [showConfig, setShowConfig] = useState(false);

  return (
    <>
      <SerialMachineSelector
        showConfig={() => {
          setShowConfig(true);
        }}
        serialPort={props.serialPort}
        serialPortConfig={serialPortConfigs?.find((machine) => machine.portName === props.serialPort)}
      />
      <SerialDropDownMachineList
        port={props.serialPort}
        showConfig={showConfig}
        setShowConfig={() => {
          setShowConfig(false);
        }}
      />
      <EnterSerialPopup serialPort={props.serialPort} show={showSetSerial(serialPortConfigs, props.serialPort)} />
    </>
  );
};

export const SerialPorts = () => {
  const { deviceId } = useParams();
  const { value: availableSerialPorts } = useAsync(() => cotService.getAllSerialPorts(deviceId!));

  return (
    <SelectPortsProvider>
      {availableSerialPorts?.map((serialPort) => (
        <PortSelector key={serialPort} serialPort={serialPort} />
      ))}
    </SelectPortsProvider>
  );
};
