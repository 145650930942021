import { store } from 'store';
import {
  CotCompany,
  CotDevice,
  CotProject,
  CotStream,
  DynamicDeviceConfig,
  MetaDeviceData,
  ParentGW,
  SerialPortConfig,
  SerialConfig,
  SerialPortMachine,
  UserProfile,
  DeviceProject,
  DeviceHubAndChilds,
} from 'types/cot.types';
import { API_MAP, getApiTuttHeaders, getXApiKeyHeaders, request } from './httpService';
import { waitUntil } from 'utils/wait.utils';

class COTService {
  private user = () => {
    return store.getState().user;
  };

  private paging = async <T>(func: (page: string) => any): Promise<Array<T>> => {
    let list: T[] = [];
    let page = 1;
    let res: any;
    while (page === 1 || res?.next) {
      res = await func(page.toString());
      list = [...list, ...res?.results];
      page++;
    }
    return list;
  };

  public getUserProfile = async (companyId: string): Promise<UserProfile> => {
    return await request(API_MAP.getUserProfile, {
      urlParams: { companyId },
      headers: getXApiKeyHeaders(this.user().apiKey),
    });
  };

  public getProjectProperty = async <Type>(
    companyId: string,
    projectId: string,
    propertyName: string
  ): Promise<Type | undefined> => {
    const res = await request(API_MAP.getProjectProperty, {
      urlParams: { companyId, projectId, propertyName },
      headers: getXApiKeyHeaders(this.user().apiKey),
    });
    if (!res?.results || res?.results.length === 0) {
      return undefined;
    }
    return res.results[0].value;
  };

  public setProjectProperty = async <Type>(
    companyId: string,
    projectId: string,
    propertyName: string,
    value: Type
  ): Promise<void> => {
    await request(API_MAP.setProjectProperty, {
      urlParams: { companyId, projectId, propertyName },
      headers: getXApiKeyHeaders(this.user().apiKey),
      payload: value,
    });
  };

  public getDeviceProperty = async <Type>(deviceId: string, propertyName: string): Promise<Type | undefined> => {
    const res = await request(API_MAP.getDeviceProperty, {
      urlParams: { deviceId, propertyName },
      headers: getXApiKeyHeaders(this.user().apiKey),
    });
    if (!res?.results || res?.results.length === 0) {
      return undefined;
    }
    return res.results[0].value;
  };

  public setDeviceProperty = async <Type>(deviceId: string, propertyName: string, value: Type): Promise<void> => {
    await request(API_MAP.setDeviceProperty, {
      urlParams: { deviceId, propertyName },
      headers: getXApiKeyHeaders(this.user().apiKey),
      payload: value,
    });
  };

  public getDeviceStream = async <T>(projectId: string, deviceId: string, streamId: string): Promise<CotStream<T>> => {
    return await request(API_MAP.getDeviceStream, {
      urlParams: { projectId, deviceId, streamId },
      headers: getXApiKeyHeaders(this.user().apiKey),
    });
  };

  public setDeviceCommand = async <T>(
    projectId: string,
    deviceId: string,
    streamId: string,
    value: string
  ): Promise<CotStream<T>> => {
    return await request(API_MAP.setDeviceCommand, {
      urlParams: { projectId, deviceId, streamId },
      headers: getXApiKeyHeaders(this.user().apiKey),
      payload: { content: value },
    });
  };

  public getCompanyName = async (companyId: string): Promise<string> => {
    const res = await request(API_MAP.getCompanyName, {
      urlParams: { companyId },
      headers: getXApiKeyHeaders(this.user().apiKey),
    });
    return res?.name;
  };

  public getSubCompanies = async (companyId: string): Promise<CotCompany[]> => {
    return await this.paging(
      async (page) =>
        await request(API_MAP.getSubCompanies, {
          urlParams: { companyId, page },
          headers: getXApiKeyHeaders(this.user().apiKey),
        })
    );
  };

  public getUserCompanies = async (companyId: string): Promise<CotCompany[]> => {
    const subCompanies = await cotService.getSubCompanies(companyId);
    const companyName = await cotService.getCompanyName(companyId);
    const userCompany: CotCompany = { name: companyName, id: companyId, parent: '' };
    const companies = [userCompany, ...subCompanies];
    return companies;
  };

  public getProjects = async (companyId: string): Promise<CotProject[]> => {
    return await this.paging(
      async (page) =>
        await request(API_MAP.getProjects, {
          urlParams: { companyId, page },
          headers: getXApiKeyHeaders(this.user().apiKey),
        })
    );
  };

  public getProjectsRecursive = async (companyId: string, userProfile: UserProfile): Promise<CotProject[]> => {
    if (userProfile.role.startsWith('P')) {
      return await this.getProjects(companyId);
    }
    const subCompanies = await this.getSubCompanies(companyId);
    const companies = [companyId, ...subCompanies.map((subCompany) => subCompany.id)];
    const projects = await Promise.all(companies.map(this.getProjects));
    return projects.flat();
  };

  public createProject = async (companyId: string, projectName: string): Promise<CotProject> => {
    const res = await request(API_MAP.createProject, {
      urlParams: { companyId },
      headers: getXApiKeyHeaders(this.user().apiKey),
      payload: { name: projectName },
    });
    return res;
  };

  public editProject = async (companyId: string, projectId: string, newName: string) => {
    await request(API_MAP.editProject, {
      urlParams: { companyId, projectId },
      headers: getXApiKeyHeaders(this.user().apiKey),
      payload: { name: newName },
    });
  };

  public getProject = async (companyId: string, projectId: string): Promise<CotProject> => {
    return await request(API_MAP.getProject, {
      urlParams: { companyId, projectId },
      headers: getXApiKeyHeaders(this.user().apiKey),
    });
  };

  public allocateDevice = async (companyId: string, projectId: string, deviceId: string): Promise<void> => {
    await request(API_MAP.allocateDevice, {
      headers: getApiTuttHeaders(this.user()),
      payload: { companyId, projectId, deviceId },
    });
  };

  public allocateGateway = async (companyId: string, projectId: string, deviceId: string): Promise<void> => {
    let metaDeviceDataGw = {
      allocation_date: new Date().toISOString(),
    };
    this.setDeviceProperty(deviceId, 'meta.device_data', metaDeviceDataGw);
    await this.allocateDevice(companyId, projectId, deviceId);
    const children = await this.getDeviceChildrenIds(deviceId);
    for (const child of children) {
      const isAssigned = async () => {
        const device = await this.getOptionalDeviceById(child);
        return device?.project === projectId;
      };
      await waitUntil(isAssigned);
    }
  };

  public deallocateDevice = async (companyId: string, projectId: string, deviceId: string): Promise<void> => {
    return await request(API_MAP.deallocateDevice, {
      headers: getApiTuttHeaders(this.user()),
      payload: { companyId, projectId, deviceId },
    });
  };

  public getDeviceById = async (deviceId: string): Promise<CotDevice> => {
    const [deviceProject, metaDeviceData, cotDevice] = await Promise.all([
      this.getDeviceProperty<DeviceProject>(deviceId, 'device.project'),
      this.getDeviceProperty<MetaDeviceData>(deviceId, 'meta.device_data'),
      request(API_MAP.getDeviceById, {
        urlParams: { deviceId },
        headers: getXApiKeyHeaders(this.user().apiKey),
      }),
    ]);

    return { ...cotDevice, metaDeviceData, deviceProject };
  };

  public getOptionalDeviceById = async (deviceId: string): Promise<CotDevice | undefined> => {
    try {
      return await this.getDeviceById(deviceId);
    } catch (err) {
      console.log(`returning optional device as undefined, error-${err}`);
    }
  };

  public getDeviceChildren = async (deviceId: string): Promise<CotDevice[]> => {
    const childrenIds = await this.getDeviceChildrenIds(deviceId);
    return await Promise.all(childrenIds.map((deviceId) => this.getDeviceById(deviceId)));
  };

  public getDeviceChildrenIds = async (deviceId: string): Promise<string[]> => {
    const cotDevice = await this.getDeviceById(deviceId);
    if (cotDevice === undefined) {
      return [];
    }
    const deviceHubAndChilds = await this.getDeviceProperty<DeviceHubAndChilds>(deviceId, 'device.hubAndChilds');
    return deviceHubAndChilds?.hub.nodes.map((node) => node.name) ?? [];
  };

  public getAllProjectDevices = async (companyId: string, projectId: string): Promise<CotDevice[]> => {
    const cotDevices: CotDevice[] = (
      await request(API_MAP.getAllProjectDevices, {
        urlParams: { companyId, projectId },
        headers: getXApiKeyHeaders(this.user().apiKey),
      })
    ).results;

    const metaDeviceDatas = await Promise.all(
      cotDevices.map((cotDevices) => this.getDeviceProperty<MetaDeviceData>(cotDevices.id, 'meta.device_data'))
    );
    cotDevices.forEach((device, index) => (device.metaDeviceData = metaDeviceDatas[index]));
    return cotDevices;
  };

  public getAllSerialPorts = async (deviceId: string): Promise<string[]> => {
    const serialConfig = await this.getDeviceProperty<SerialConfig>(deviceId, 'device.serial.config');
    return serialConfig!.serialPortInlets;
  };

  public getAllSerialPortMachines = async (deviceId: string): Promise<SerialPortMachine[]> => {
    const serialConfig = await this.getDeviceProperty<SerialConfig>(deviceId, 'device.serial.config');
    return serialConfig!.serialPortMachines;
  };

  public getSerialPortConfigs = async (deviceId: string): Promise<SerialPortConfig[] | undefined> => {
    const dynamicDeviceConfig = await this.getDeviceProperty<DynamicDeviceConfig>(deviceId, 'dynamic.device.config');
    return dynamicDeviceConfig!.serialPortConfigs;
  };

  public setSerialPortConfigs = async (serialPortConfigs: SerialPortConfig[], deviceId: string): Promise<void> => {
    const dynamicDeviceConfig = await this.getDeviceProperty<DynamicDeviceConfig>(deviceId, 'dynamic.device.config');
    await this.setDeviceProperty<DynamicDeviceConfig>(deviceId, 'dynamic.device.config', {
      ...dynamicDeviceConfig!,
      serialPortConfigs,
    });
  };

  public getGwByChildSerial = async (serialNumber: string): Promise<ParentGW> => {
    return await request(API_MAP.getGwByChildSerial, {
      headers: getApiTuttHeaders(this.user()),
      payload: { serialNumber },
    });
  };

  public getSerialNumberFromDeviceId = async (deviceId: string): Promise<string> => {
    const data = await request(API_MAP.getInfoFromDeviceId, {
      urlParams: { deviceId },
    });
    return data.serial_number;
  };
}
export const cotService = new COTService();
