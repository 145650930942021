import { useEffect, useState } from 'react';
import { useAppSelector } from 'hooks/useApp';
import { selectUser } from 'store';
import { useAsync } from 'hooks/useAsync';
import { useSpinner } from 'hooks/useSpinner';
import { Col, Dropdown, Form, Row } from 'react-bootstrap';
import { cotService } from 'services/cot.service';
import { UseFormRegister, FieldValues, UseFormClearErrors } from 'react-hook-form';
interface CompanySelectProps {
  selectedCompanyId: React.MutableRefObject<string>;
  register: UseFormRegister<FieldValues>;
  clearErrors: UseFormClearErrors<FieldValues>;
  errors: any;
  errClassName: string;
}
export const CompanySelect = ({
  selectedCompanyId,
  register,
  clearErrors,
  errors,
  errClassName,
}: CompanySelectProps) => {
  const USE_FORM_ELEMENT_NAME = 'companySelect';
  const user = useAppSelector(selectUser);
  const showSpinner = useSpinner();
  const [searchCompany, setSearchCompany] = useState('');
  const [companyIdState, setCompanyIdState] = useState('');
  const { value: companies, status: companiesStatus } = useAsync(() => cotService.getUserCompanies(user.company));
  useEffect(() => {
    showSpinner(companiesStatus === 'pending');
    selectedCompanyId.current = companyIdState;
  }, [companiesStatus, companyIdState, selectedCompanyId, showSpinner]);
  useEffect(() => {
    if (companyIdState) clearErrors(USE_FORM_ELEMENT_NAME);
  }, [companyIdState, clearErrors]);
  return (
    <Row>
      <Col>
        <Form.Group className="mb-2" controlId="clinicName">
          <Dropdown>
            <Dropdown.Toggle
              className={'p-3 ' + (errors[USE_FORM_ELEMENT_NAME] ? errClassName : 'border')}
              style={{ width: '100%' }}
              variant="off"
              value={companyIdState}
              {...register(USE_FORM_ELEMENT_NAME, { required: true })}
            >
              {companies?.find((company) => company.id === companyIdState)?.name ?? 'Please select distributor'}
            </Dropdown.Toggle>

            <Dropdown.Menu style={{ width: '100%', maxHeight: '280px', overflowY: 'auto' }}>
              <Dropdown.Header>
                <input
                  type="text"
                  placeholder="Search.."
                  id="ourinput"
                  onChange={(e) => {
                    setSearchCompany(e.target.value);
                  }}
                />
              </Dropdown.Header>
              <Dropdown.Divider />
              {companies
                ?.filter((company) => company.name.toLowerCase().includes(searchCompany.toLowerCase()))
                .map((company) => (
                  <Dropdown.Item key={company.id} value={company.id} onClick={() => setCompanyIdState(company.id)}>
                    {company.name}
                  </Dropdown.Item>
                ))}
            </Dropdown.Menu>
          </Dropdown>
        </Form.Group>
      </Col>
    </Row>
  );
};
