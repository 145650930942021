import { ClinicDetails } from 'components/ClinicDetails/ClinicDetails';
import { EditClinicInfo } from 'components/EditClinicInfo/EditClinicInfo';
import { LinkButton } from 'components/LinkButton';
import { MutableRefObject, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { FieldErrors, FieldValues, UseFormRegister } from 'react-hook-form';
import { ProjectMetadata } from 'types/cot.properties.types';
import { CotProject } from 'types/cot.types';

export function ClinicView({
  projectName,
  project,
  projectMetadata,
  errors,
  register,
}: {
  projectName: MutableRefObject<string>;
  errors: FieldErrors<FieldValues>;
  register: UseFormRegister<FieldValues>;
  projectMetadata: ProjectMetadata;
  project?: CotProject;
}) {
  const [editName, setEditName] = useState(false);
  return (
    <>
      {editName ? (
        <EditClinicInfo
          projectName={projectName}
          errors={errors}
          register={register}
          projectMetadata={projectMetadata}
        ></EditClinicInfo>
      ) : (
        <Row>
          <Col>
            <ClinicDetails projectMetadata={projectMetadata} project={project}></ClinicDetails>
          </Col>
          <Col>
            <LinkButton onClick={() => setEditName(true)}>Edit</LinkButton>
          </Col>
        </Row>
      )}
    </>
  );
}
