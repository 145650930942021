import { ChildrenMachine } from 'components/ChildrenMachine/ChildrenMachine';
import { useAsync } from 'hooks/useAsync';
import { Col, Container, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { cotService } from 'services/cot.service';

export const GWDashboard = () => {
  const { deviceId } = useParams();
  const { value: deviceChildren } = useAsync(() => cotService.getDeviceChildren(deviceId!), { interval: 5000 });
  return (
    <Container>
      <Row>
        <Col className="d-flex mt-4 mb-2 align-items-center">
          <div className="setup-section-title underline flex-grow-1">Connected Devices</div>
        </Col>
      </Row>
      <ChildrenMachine deviceChildren={deviceChildren} />
    </Container>
  );
};
