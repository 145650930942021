import search from 'assets/images/search.svg';
import { InputText } from 'components/InputText/InputText';
import React, { useState } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { createSearchParams, Link, useParams, useSearchParams } from 'react-router-dom';
import { routesConstant } from 'routes/routes.const';
import { Project } from 'types/cot.properties.types';
import { CotCompany } from 'types/cot.types';
import { concatAddress } from 'utils/concatAddress';
import { GWSetupProgressBar } from '../components/GWSetupProgressBar/GWSetupProgressBar';
import '../GWSetupWizard.css';
import { deviceAllocationLogic } from './utils/GWSetupClinicLogic';
import { isInventoryProject } from 'utils/project.utils';
import { useGetOptionalDeviceByIdQuery } from 'hooks/queries/useGetOptionalDeviceByIdQuery';
import { useGetUserCompaniesQuery } from 'hooks/queries/useGetUserCompaniesQuery';
import { useGetFlatProjectsQuery } from 'hooks/queries/useGetFlatProjectsQuery';
import { useSpinnerEffect } from 'hooks/useSpinner';
import { isAllocated } from 'utils/device.utils';

const ProjectComponent = ({ project, companies }: { project: Project; companies: CotCompany[] }) => {
  const companyName = companies.find((company) => company.id === project.company)?.name;
  return (
    <GoToProjectLink project={project}>
      <div className="setup-section-title">{project.name}</div>
      <div className="setup-section-subtitle">{concatAddress(project.metadata?.address)}</div>
      <div className="setup-section-subtitle">{`From company ${companyName}`}</div>
    </GoToProjectLink>
  );
};

export const GoToProjectLink = ({ children, project }: { children?: React.ReactNode; project: Project }) => {
  const { deviceId } = useParams();
  const [searchParams] = useSearchParams();
  return (
    <Link
      to={{
        pathname: deviceId
          ? routesConstant.gwSetupSetLocation.relatirve({
              companyId: project.company,
              projectId: project.id,
              deviceId,
            })
          : routesConstant.editClinic.absolute({
              companyId: project.company,
              projectId: project.id,
            }),
        search: createSearchParams(searchParams).toString(),
      }}
      style={{ textDecoration: 'none' }}
    >
      {children}
    </Link>
  );
};

export const Deallocate = () => {
  const { deviceId } = useParams();
  const { data: companies } = useGetUserCompaniesQuery();
  const { data: flatProjects } = useGetFlatProjectsQuery();
  const { deallocate } = deviceAllocationLogic.useDeviceAllocationLogic(deviceId!);
  const { data: device } = useGetOptionalDeviceByIdQuery(deviceId!);
  const project = flatProjects?.find((project) => project.id === device?.project);
  if (!companies || !project) {
    return <></>;
  }
  return (
    <>
      <Container>
        <div className="setup-section-title">The device already assigned:</div>
        <div className="ms-5">
          <ProjectComponent project={project} companies={companies} />
          <Button variant="danger" className={'rounded-pill ps-3 pe-3 pt-2 pb-2 me-3'} onClick={() => deallocate()}>
            Deallocate
          </Button>
          <GoToProjectLink project={project}>
            <Button className={'nb-submit-button rounded-pill ps-3 pe-3 pt-2 pb-2'}>Continue</Button>
          </GoToProjectLink>
        </div>
      </Container>
    </>
  );
};

export const ClinicList = ({ title }: { title: string }) => {
  const { deviceId } = useParams();
  const [queryFilter, setQueryFilter] = useState('');
  const [searchParams] = useSearchParams();
  const { data: companies } = useGetUserCompaniesQuery();
  const { data: flatProjects } = useGetFlatProjectsQuery();
  useSpinnerEffect(!flatProjects);
  return (
    <Container>
      <Row>
        <Col className="setup-step-title">{title}</Col>
      </Row>
      <Row className="pb-5 align-items-center">
        <Col>
          <InputText
            placeholder="Search"
            icon={search}
            onChange={(e) => {
              setQueryFilter(e.target.value);
            }}
          />
        </Col>
        <Col>
          <Link
            to={{
              pathname: deviceId
                ? routesConstant.gwSetupNewClinic.relatirve({
                    deviceId,
                  })
                : routesConstant.newClinic.absolute(),
              search: createSearchParams(searchParams).toString(),
            }}
            style={{ textDecoration: 'none' }}
          >
            <Button className={'nb-submit-button rounded-pill ps-3 pe-3 pt-2 pb-2'}>Add New</Button>
          </Link>
        </Col>
      </Row>
      <Row>
        <Col>
          {flatProjects
            ?.filter((project) => project.name.toLowerCase().includes(queryFilter.toLowerCase()))
            .filter((project) => !isInventoryProject(project))
            .map((project) => (
              <React.Fragment key={project.id}>
                <ProjectComponent project={project} companies={companies!}></ProjectComponent>
                <hr />
              </React.Fragment>
            ))}
        </Col>
      </Row>
    </Container>
  );
};

const useIsLoading = (deviceId: string) => {
  const { isLoading: isDeviceLoading } = useGetOptionalDeviceByIdQuery(deviceId);
  const { isLoading: isCompaniesLoading } = useGetUserCompaniesQuery();
  const { isLoading: isFlatProjectsLoading } = useGetFlatProjectsQuery();
  return isDeviceLoading || isCompaniesLoading || isFlatProjectsLoading;
};

export const GWSetupClinicSearch = () => {
  const { deviceId } = useParams();
  const { data: device } = useGetOptionalDeviceByIdQuery(deviceId!);
  const isLoading = useIsLoading(deviceId!);
  useSpinnerEffect(isLoading);
  if (isLoading) {
    return <></>;
  }
  return (
    <>
      <GWSetupProgressBar currentStep={'Clinic'} />
      {isAllocated(device) ? <Deallocate /> : <ClinicList title="Assign to a Clinic" />}
    </>
  );
};
