import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { AppPopup } from 'components/AppPopup/AppPopup';
import { CustomKeyboard } from 'components/CustomKeyboard/CustomKeyboard';
import { SpinnerLayout } from 'layouts/SpinnerLayout';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { HashRouter } from 'react-router-dom';
import { AppRoute } from 'routes/AppRoutes';
import './i18n';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { store } from './store/store';

const container = document.getElementById('root')!;
const root = createRoot(container);
const queryClient = new QueryClient();

root.render(
  <React.StrictMode>
    <HashRouter>
      <Provider store={store}>
        <QueryClientProvider client={queryClient}>
          <AppRoute />
          <AppPopup />
          <SpinnerLayout />
          <CustomKeyboard />
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </Provider>
    </HashRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
