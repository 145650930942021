import { useAsync } from 'hooks/useAsync';
import { Col, Container, Row } from 'react-bootstrap';
import { gwService } from 'services/gw.service';

export const GwInfo = () => {
  const { value: gwInfoItems } = useAsync(gwService.getGwInfo);
  if (!gwInfoItems) {
    return <></>;
  }
  return (
    <Container>
      <Row>
        <Col className="setup-step-title">Gw Info</Col>
      </Row>
      {gwInfoItems.map((gwInfoItem) => (
        <Row className="mt-2" key={gwInfoItem.title}>
          <Col xs="3" style={{ fontWeight: 'bold' }}>
            {gwInfoItem.title}:
          </Col>
          <Col>{gwInfoItem.value}</Col>
        </Row>
      ))}
    </Container>
  );
};
