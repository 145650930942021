import { GWSetupProgressBar } from '../components/GWSetupProgressBar/GWSetupProgressBar';
import './styles.scss';
import { NewClinic } from './NewClinic';

export const GWSetupNewClinic = () => {
  return (
    <>
      <GWSetupProgressBar currentStep={'Clinic'} />
      <NewClinic></NewClinic>
    </>
  );
};
