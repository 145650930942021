import iTooltip from 'assets/images/i_tooltip.svg';
import { ChildrenMachine } from 'components/ChildrenMachine/ChildrenMachine';
import { OfflineAlert } from 'components/Alerts/OfflineAlert';
import { useAsync } from 'hooks/useAsync';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { routesConstant } from 'routes/routes.const';
import { cotService } from 'services/cot.service';
import { GWSetupProgressBar } from '../components/GWSetupProgressBar/GWSetupProgressBar';
import { NearbyMachines } from 'components/NearbyMachines/NearbyMachines';
import { AddNearbyMachine } from 'components/AddNearbyMachine/AddNearbyMachine';
import { SerialMachineConfiguration } from '../GWSerialMachines/SerialMachineConfiguration';

export const GWSetupSetMachines = () => {
  const { companyId, projectId, deviceId } = useParams();
  const { value: deviceChildren } = useAsync(() => cotService.getDeviceChildren(deviceId!), { interval: 5000 });
  const navigate = useNavigate();

  return (
    <>
      <GWSetupProgressBar currentStep={'Machines'} />
      <OfflineAlert deviceId={deviceId!} projectId={projectId!} />
      <Container>
        <Row>
          <Col className="setup-step-title mb-4">Add &amp; Remove Machines</Col>
        </Row>
        <SerialMachineConfiguration />
        <Row>
          <Col className="d-flex mt-4 mb-2 align-items-center">
            <div className="setup-section-title underline flex-grow-1">Lan Connections</div>
            <Button variant="link">
              <img alt="action" src={iTooltip} />
            </Button>
          </Col>
        </Row>
        <ChildrenMachine deviceChildren={deviceChildren}></ChildrenMachine>
        <NearbyMachines
          companyId={companyId!}
          projectId={projectId!}
          deviceIdsToFilter={deviceChildren?.map((device) => device.id) ?? []}
        />
        <AddNearbyMachine companyId={companyId!} projectId={projectId!} />
        <Row className="mt-5 mb-5 text-center">
          <Col>
            <Link
              to={routesConstant.gwSetupMachinesRecommendation.relatirve({
                deviceId: deviceId!,
                companyId: companyId!,
                projectId: projectId!,
              })}
              style={{ textDecoration: 'none' }}
            >
              <Button className="outlined-btn me-3">Back</Button>
            </Link>
            <Button
              className="filled-btn"
              onClick={() =>
                navigate(
                  routesConstant.gwSetupCompleted.relatirve({
                    companyId: companyId!,
                    projectId: projectId!,
                    deviceId: deviceId!,
                  })
                )
              }
            >
              Confirm
            </Button>
          </Col>
        </Row>
      </Container>
    </>
  );
};
