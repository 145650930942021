import { SetGeolocation } from 'pages/GWSetupWizard/GWSetupNewClinic/SetGeolocation';
import { Col, Form, InputGroup, Row } from 'react-bootstrap';
import { FieldErrors, FieldValues, UseFormRegister } from 'react-hook-form';
import { ProjectMetadata } from 'types/cot.properties.types';

const ERR_CLASS_NAME = 'error-border';

function initAddresses(projectMetadata: ProjectMetadata) {
  projectMetadata.address = projectMetadata.address ? projectMetadata.address : { line1: '', line2: '' };
}

export function EditClinicInfo({
  projectName,
  projectMetadata,
  errors,
  register,
}: {
  projectName: React.MutableRefObject<string>;
  errors: FieldErrors<FieldValues>;
  register: UseFormRegister<FieldValues>;
  projectMetadata: ProjectMetadata;
}) {
  initAddresses(projectMetadata);
  return (
    <>
      <Row>
        <Col className="setup-section-title">Clinic Info</Col>
      </Row>
      <Row>
        <Col>
          <Form.Group className="mb-2" controlId="clinicName">
            <Form.Control
              className={'setup-form-field-text ' + (errors.name ? ERR_CLASS_NAME : '')}
              type="text"
              placeholder="Name*"
              defaultValue={projectName.current}
              {...register('name', {
                required: true,
                onChange: (e) => {
                  projectName.current = e.target.value;
                },
              })}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group className="mb-2" controlId="clinicAddress">
            <InputGroup>
              <Form.Control
                className={'setup-form-field-text ' + (errors.address1 ? ERR_CLASS_NAME : '')}
                type="text"
                placeholder="Address line 1*"
                defaultValue={projectMetadata.address?.line1}
                {...register('address1', {
                  required: true,
                  onChange: (e) => {
                    projectMetadata.address!.line1 = e.target.value;
                  },
                })}
              />
            </InputGroup>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group className="mb-2" controlId="clinicAddress">
            <InputGroup>
              <Form.Control
                className="setup-form-field-text"
                type="text"
                placeholder="Address line 2"
                defaultValue={projectMetadata.address?.line2}
                onChange={(e) => {
                  projectMetadata.address!.line2 = e.target.value;
                }}
              />
            </InputGroup>
          </Form.Group>
        </Col>
      </Row>
      <SetGeolocation projectMetadata={projectMetadata}></SetGeolocation>
    </>
  );
}
