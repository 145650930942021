import { Button, Modal } from 'react-bootstrap';
import { useAppDispatch, useAppSelector } from '../../hooks/useApp';
import { hidePopup, selectPopupState } from '../../store/slices/popupSlice';

export const AppPopup = () => {
  const popupState = useAppSelector(selectPopupState);
  const dispatch = useAppDispatch();
  return (
    <>
      <Modal show={popupState.show}>
        <Modal.Body>{popupState.message}</Modal.Body>
        <Button variant="primary" onClick={() => dispatch(hidePopup())}>
          Ok
        </Button>
      </Modal>
    </>
  );
};
