import { Outlet } from 'react-router-dom';
import { NBNavBar } from 'pages/NavBar/NBNavBar';

export const NowBrandingLayout = ({
  children,
  iconsProvider,
}: {
  children?: React.ReactNode;
  iconsProvider: () => Array<JSX.Element | undefined>;
}) => {
  return (
    <>
      <NBNavBar iconsProvider={iconsProvider} />
      {children}
      <Outlet />
    </>
  );
};
