import { useLoadIcon } from 'hooks/useLoadIcon';
import { Button, Container } from 'react-bootstrap';
import { createSearchParams, Link, useParams, useSearchParams } from 'react-router-dom';
import { routesConstant } from 'routes/routes.const';
import { GWSetupProgressBar } from '../components/GWSetupProgressBar/GWSetupProgressBar';
import '../GWSetupWizard.css';

const iconNamePerModel = (model: string): string => {
  if (['TTA-ECO-10', 'T-Top-10', 'T-EDGE-11', 'T-EDGE-10'].includes(model)) {
    return 'tabletop';
  }
  if (['T-Lab Eco V60', 'T-Lab Eco V85'].includes(model)) {
    return 'horizontal';
  }
  return 'gw';
};

export const GWSetupStart = () => {
  const { deviceId } = useParams();
  const [search] = useSearchParams();
  const model = search.get('model') ?? 'Gateway';
  const deviceIcon = useLoadIcon(`${iconNamePerModel(model)}_device.svg`);

  return (
    <>
      <Container className="text-center">
        <div className="mb-2">Installation wizard</div>
        <div className="setup-step-title">{model}</div>
        <div className="setup-step-title mb-5" style={{ textOverflow: 'ellipsis', width: '100%', overflow: 'hidden' }}>
          {deviceId}
        </div>
        <div className="mb-5">
          <img src={deviceIcon} alt=""></img>
        </div>
        <GWSetupProgressBar theme="light" size="large" completedStep="Identification" />
        <div className="fs-20 mb-3">Setup the gateway in 3 simple steps</div>
        <div className="fs-14 ms-5 me-5 mb-5">
          Make sure the gateway is connected to the clinic's secure WiFi network, and that all relevant machines are on.
        </div>
        <Link
          to={{
            pathname: routesConstant.gwSetupClinicSearch.relatirve({ deviceId: deviceId! }),
            search: createSearchParams(search).toString(),
          }}
        >
          <Button className="filled-btn ps-5 pe-5 pt-2 pb-2 mb-3">Let's start</Button>
        </Link>
      </Container>
    </>
  );
};
