export const validateNotEmptyValue = (value: string): boolean => {
  return value.length > 0;
};
export const validatePhoneNumber = (value: string): boolean => {
  const phoneRegex = /^[0-9-]+$/; // Regex pattern for numbers and hyphens
  return !value.length || phoneRegex.test(value);
};
export const validateEmail = (value: string): boolean => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Basic email regex pattern
  return emailRegex.test(value);
};
