import { Button, Col, ListGroup, Row } from 'react-bootstrap';
import classnames from 'classnames';
import styles from './wifi.module.scss';
import { WifiDetails } from './model/WifiDetails';
import { useWifiContext } from './WifiContext';
import * as WifiConstants from './WifiConstants';
import { gwService } from 'services/gw.service';
import { useSpinner } from 'hooks/useSpinner';
import { useShowPopup } from 'hooks/useShowPopup';
import { WifiSvg } from 'components/svg.components/WifiSvg';

export const WifiItem = (wifiDetails: WifiDetails, key: string) => {
  const showSpinner = useSpinner();
  const showPopup = useShowPopup();
  const wifiContext = useWifiContext();
  const clickOnItem = (wifiDetails: WifiDetails) => {
    if (wifiDetails.connected) return;
    if (wifiContext.privacyPolicyDiv?.className.indexOf(WifiConstants.CHECKED_CLASS) === -1) {
      wifiContext.privacyPolicyDiv.className += ' ' + styles.required;
    } else {
      wifiContext.setWifiDetails(wifiDetails);
      wifiContext.setShowWifiConnectModal(true);
    }
  };

  const disconnect = async (): Promise<void> => {
    try {
      showSpinner(true);
      await gwService.wifiDisconnect();
      showPopup('Disconnected successfully');
    } catch (e) {
      showPopup('Failed to disconnect');
    } finally {
      showSpinner(false);
    }
  };

  return (
    <ListGroup.Item
      className={classnames('d-flex justify-content-between1', styles.listGroupItem)}
      key={key}
      onClick={() => clickOnItem(wifiDetails)}
    >
      <Row className="w-100">
        <Col xs={1}>
          <WifiSvg signalStrength={wifiDetails.signal} theme="dark" size="small" />
        </Col>
        <Col xs={8} className={wifiDetails.connected ? styles.connected : ''}>
          {wifiDetails.ssid}
        </Col>
        <Col xs={3}>
          <Button
            className={wifiDetails.connected ? classnames(styles.wifiButton, styles.wifiButtonWidth120) : 'invisible'}
            onClick={disconnect}
          >
            Disconnect
          </Button>
        </Col>
      </Row>
    </ListGroup.Item>
  );
};
