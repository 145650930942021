import gwDeviceSmileIcon from 'assets/images/gw_device_smile.svg';
import { ChildrenMachine } from 'components/ChildrenMachine/ChildrenMachine';
import { useAsync } from 'hooks/useAsync';
import { Col, Container, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { cotService } from 'services/cot.service';
import { GWSetupProgressBar } from '../components/GWSetupProgressBar/GWSetupProgressBar';
import '../GWSetupWizard.css';
import { ClinicDetails } from 'components/ClinicDetails/ClinicDetails';
import { ContactList } from 'components/ContactList/ContactList';
import { GwLocationInfo } from 'components/GwLocationInfo/GwLocationInfo';
import { NearbyMachines } from 'components/NearbyMachines/NearbyMachines';
import { useGetProjectDataQuery } from 'hooks/queries/useGetProjectDataQuery';

export const GWSetupCompleted = () => {
  const { companyId, projectId, deviceId } = useParams();
  const { value: deviceChildren } = useAsync(() => cotService.getDeviceChildren(deviceId!), { interval: 5000 });
  const { projectMetadata, project, gwLocations } = useGetProjectDataQuery(companyId!, projectId!);

  return (
    <>
      <GWSetupProgressBar allCompleted={true} />
      <Container>
        <Row>
          <Col className="text-center setup-section-title font-weight-normal mb-5 margin-top-70">Ready to go!</Col>
        </Row>
        <Row>
          <Col className="text-center mb-5">
            <img src={gwDeviceSmileIcon} alt=""></img>
          </Col>
        </Row>
        <Row>
          <Col
            className="text-center setup-step-title font-weight-bold"
            style={{ textOverflow: 'ellipsis', width: '100%', overflow: 'hidden' }}
          >
            Gateway {deviceId}
          </Col>
        </Row>
        <Row>
          <Col md className="mt-5">
            <ClinicDetails projectMetadata={projectMetadata} project={project}></ClinicDetails>
          </Col>
          <Col md className="mt-5">
            <GwLocationInfo gwLocations={gwLocations} deviceId={deviceId!}></GwLocationInfo>
          </Col>
          <Col md className="mt-5">
            <Row>
              <Col className="setup-section-title">Contacts</Col>
            </Row>
            <ContactList contact={projectMetadata?.contact!} contacts={[]}></ContactList>
          </Col>
          <Col lg className="mt-5">
            <Row>
              <Col className="setup-section-title">Connected Devices</Col>
            </Row>
            <ChildrenMachine deviceChildren={deviceChildren} />
          </Col>
        </Row>
        <NearbyMachines
          companyId={companyId!}
          projectId={projectId!}
          deviceIdsToFilter={deviceChildren?.map((device) => device.id) ?? []}
        />
      </Container>
    </>
  );
};
