import { useShowPopup } from 'hooks/useShowPopup';
import { useSpinner } from 'hooks/useSpinner';
import { useState } from 'react';
import { Button, Col, Form, InputGroup, Row } from 'react-bootstrap';
import { cotService } from 'services/cot.service';

const allocateBySerial = async (serial: string, companyId: string, projectId: string) => {
  const parentGW = await cotService.getGwByChildSerial(serial);
  await cotService.allocateGateway(companyId, projectId, parentGW.parentDeviceId);
};

export const AddNearbyMachine = ({ companyId, projectId }: { companyId: string; projectId: string }) => {
  const [serial, setSerial] = useState('');
  const showSpinner = useSpinner();
  const showPopup = useShowPopup();
  const allocate = async (serial: string) => {
    try {
      showSpinner(true);
      await allocateBySerial(serial, companyId, projectId);
      showPopup('Tuttnauer machine added');
    } catch (error) {
      console.error(error);
      showPopup('Failed to add machine');
    } finally {
      showSpinner(false);
    }
  };
  return (
    <>
      <Row>
        <Col className="mt-5">You can add another Tuttnauer WiFi machines</Col>
      </Row>
      <Row>
        <Col>
          <InputGroup>
            <Form.Control
              className="setup-form-field-text"
              type="text"
              placeholder="Serial number"
              value={serial}
              onChange={(e) => setSerial(e.target.value)}
            />
            <Button
              className="filled-btn"
              onClick={() => {
                allocate(serial);
                setSerial('');
              }}
            >
              Add
            </Button>
          </InputGroup>
        </Col>
      </Row>
    </>
  );
};
