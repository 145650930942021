import { useQueryClient } from '@tanstack/react-query';
import { GET_SERIAL_PORT_CONFIGS } from 'constants/queryKeys';
import { useAsync } from 'hooks/useAsync';
import { useParams } from 'react-router-dom';
import { cotService } from 'services/cot.service';
import { SerialPortConfig } from 'types/cot.types';
import { useSelectPortsContext } from './SelectPortsContext';
import styles from './serial.machines.module.scss';
import { useSpinner, useSpinnerEffect } from 'hooks/useSpinner';
import { showSpinner } from 'store';

const MachineListItem = (props: { machineType: string; port: string; setShowConfig: () => void }) => {
  const { deviceId, projectId } = useParams();
  const queryClient = useQueryClient();
  const showSpinner = useSpinner();

  const handleButtonClick = async (machineType: string, port: string) => {
    try {
      showSpinner(true);
      const portConfig: SerialPortConfig = {
        portName: port,
        machineType,
      };
      const serialPortConfigs = await cotService.getSerialPortConfigs(deviceId!);
      let newSerialPortConfig;
      if (serialPortConfigs) {
        newSerialPortConfig = serialPortConfigs?.filter((config) => config.portName !== port);
        newSerialPortConfig.push(portConfig);
      } else {
        newSerialPortConfig = [portConfig];
      }
      await cotService.setSerialPortConfigs(newSerialPortConfig!, deviceId!);
      cotService.setDeviceCommand(projectId!, deviceId!, 'sys___actions', 'config');
      queryClient.invalidateQueries([GET_SERIAL_PORT_CONFIGS]);
    } finally {
      showSpinner(false);
    }
  };

  return (
    <>
      <div
        className={styles.machinesList + ' d-flex flex-row'}
        onClick={() => {
          handleButtonClick(props.machineType, props.port);
          props.setShowConfig();
        }}
      >
        <div className="machine-icon"></div>
        <div className={'d-flex align-items-center'} key={props.machineType}>
          {props.machineType}
        </div>
      </div>
      <hr />
    </>
  );
};

export const SerialPortConfiguration = (props: { port: string; setShowConfig: () => void }) => {
  const { deviceId } = useParams();
  const { value: serialPortMachines } = useAsync(() => cotService.getAllSerialPortMachines(deviceId!));
  const buttons = serialPortMachines?.flatMap((machine) => machine.machineType);
  const context = useSelectPortsContext();
  useSpinnerEffect(!serialPortMachines);
  return (
    <>
      {buttons?.map((machineType) => (
        <div
          key={machineType}
          onClick={() => {
            if (machineType !== 'Atos') {
              context.showDescription(machineType);
            }
          }}
        >
          <MachineListItem machineType={machineType} port={props.port} setShowConfig={props.setShowConfig} />
        </div>
      ))}
    </>
  );
};
