import { Col, Form, Row } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './floatingInput.scss';
import classNames from 'classnames';
import { FloatingInputProps } from './FloatingProps';

const FloatingLabelInput = ({ label, className, readonly, value, onChange, validate }: FloatingInputProps) => {
  const [typed, setTyped] = useState(Boolean(value) || readonly);
  const [text, setText] = useState(value || '');
  const [error, setError] = useState(false);

  useEffect(() => {
    setText(value || ''); // Update the 'text' state when 'value' prop changes
    setTyped(Boolean(value) || readonly);
  }, [value, readonly]);

  const handleFocus = () => {
    setTyped(true);
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    if (e.target.value.length === 0) {
      setTyped(false);
    }
    if (validate && !validate(e.target.value)) {
      setError(true);
    } else {
      setError(false);
    }

    setText(e.target.value);
  };
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    setText(inputValue);

    if (onChange) {
      onChange(inputValue);
    }
  };

  return (
    <Row>
      <Col>
        <Form.Group className={classNames('floating-label-group', className || '')}>
          <Form.Control
            type="text"
            value={text}
            onFocus={handleFocus}
            onBlur={handleBlur}
            onChange={handleChange}
            readOnly={readonly}
            className={error ? 'error-field' : ''}
          />
          <Form.Label className={classNames({ active: typed }, { 'has-value': text.length > 0 })}>{label}</Form.Label>
          {error && <span className="error-message">Invalid input</span>}
        </Form.Group>
      </Col>
    </Row>
  );
};

export default FloatingLabelInput;
