import nav_tutt_logo from 'assets/images/nav_tutt_logo.svg';
import { Container, Nav, Navbar } from 'react-bootstrap';
import './NBNavbar.css';

const NavIcons = ({iconsProvider}:{iconsProvider: () => Array<JSX.Element | undefined>}) => {
  return (
    <>
      {iconsProvider()
        .filter((icon) => icon)
        .map((icon, indx) => (
          <div key={indx} className="p-1">
            {icon}
          </div>
        ))}
    </>
  );
};

export const NBNavBar = ({iconsProvider}:{iconsProvider: () => Array<JSX.Element | undefined>}) => {
  const cl = `navbar shadow-sm`;
  return (
    <Navbar className={cl} sticky="top">
      <Container>
        <Nav>
          <img src={nav_tutt_logo} alt=""></img>
        </Nav>
        <Nav>
          <NavIcons iconsProvider={iconsProvider}/>
        </Nav>
      </Container>
    </Navbar>
  );
};
