import { Button, Modal } from 'react-bootstrap';
import { InfoPopup } from './InfoPopup';
import { useSelectPortsContext } from './SelectPortsContext';
import { SerialPortConfiguration } from './SerialPortConfiguration';

const InfoPopupModal = () => {
  const context = useSelectPortsContext();
  return (
    <Modal show={context.showDescriptionModal} onHide={() => context.setShowDescriptionModal(false)}>
      <Modal.Body>
        <InfoPopup
          machineType={[context.machineTypeClicked]}
          clicked={() => {
            context.setShowDescriptionModal(false);
          }}
        />
      </Modal.Body>
    </Modal>
  );
};

export const SerialDropDownMachineList = (props: { port: string; showConfig: boolean; setShowConfig: () => void }) => {
  return (
    <>
      <InfoPopupModal />
      <Modal show={props.showConfig}>
        <Modal.Header>
          <Button className="btn-close" onClick={() => props.setShowConfig()}></Button>
        </Modal.Header>
        <Modal.Body>
          <SerialPortConfiguration port={props.port} setShowConfig={props.setShowConfig} />
        </Modal.Body>
      </Modal>
    </>
  );
};
