import { ChildrenMachine } from 'components/ChildrenMachine/ChildrenMachine';
import { useAsync } from 'hooks/useAsync';
import { Col, Row } from 'react-bootstrap';
import { cotService } from 'services/cot.service';

export const NearbyMachines = ({
  companyId,
  projectId,
  deviceIdsToFilter,
}: {
  companyId: string;
  projectId: string;
  deviceIdsToFilter: string[];
}) => {
  const { value: allProjectDevices } = useAsync(() => cotService.getAllProjectDevices(companyId, projectId), {
    interval: 5000,
  });
  const nearbyMachines = allProjectDevices
    ?.filter((device) => device.metaDeviceData?.className && device.metaDeviceData?.className !== 'Gateway')
    .filter((device) => !deviceIdsToFilter.includes(device.id));
  return (
    <>
      <Row>
        <Col className="setup-section-title mt-5">Tuttnauer WiFi machines</Col>
      </Row>
      {nearbyMachines?.length ? <></> : 'No nearby machines'}
      <ChildrenMachine deviceChildren={nearbyMachines} />
    </>
  );
};
