import { Geolocation } from 'types/cot.properties.types';

export const getGeolocation = (): Promise<GeolocationPosition> => {
  return new Promise<GeolocationPosition>((resolve, reject) =>
    navigator.geolocation.getCurrentPosition(
      (location) => resolve(location),
      (error) => reject(error),
      { enableHighAccuracy: true }
    )
  );
};

export const concatenateGeolocation = (geolocation: Geolocation | undefined) => {
  if (geolocation) {
    return `lat: ${geolocation.latitude}, lon: ${geolocation.longitude}`;
  }
  return '';
};
