import style from './WifiSvg.module.css';
import { wifiStrength } from 'utils/wifi.utils';
import { ReactComponent as NoWifiSignal } from 'assets/images/no-wifi-signal.svg';
import { ReactComponent as WifiIcon } from 'assets/images/wifi.svg';

const sizes = {
  medium: { width: '41.939', height: '32.993' },
  small: { width: '21.656', height: '16.991' },
};

type WifiIconProp = {
  signalStrength: number;
  theme: 'dark' | 'bright';
  size: 'medium' | 'small';
};

const levelClassName = (signal: number) => {
  let className = '';
  for (let n = 0; n <= signal; n++) {
    className += ' ' + style['level' + n];
  }
  return className;
};

export const WifiSvg = ({ signalStrength, theme, size }: WifiIconProp) => {
  const colors = {
    dark: getComputedStyle(document.documentElement).getPropertyValue('--dark-blue'),
    bright: getComputedStyle(document.documentElement).getPropertyValue('--white'),
  };
  const color = colors[theme];
  const { height, width } = sizes[size];
  const signal = wifiStrength(signalStrength);

  if (signal < 0) {
    return <NoWifiSignal />;
  }
  const className = levelClassName(signal);
  return <WifiIcon className={className} fill={color} height={height} width={width} />;
};
