import { AxiosError } from 'axios';
import { handleCotError } from './handleCotError';

export function handleAxiosError(e: unknown, showPopup: (message: string) => void): boolean {
  const axiosError: AxiosError<any, any> = e as AxiosError;
  if (axiosError == undefined) {
    return false;
  }
  const handlers = [handleCotError];
  return handlers.some((handler) => handler(axiosError, showPopup));
}
