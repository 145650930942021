import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit';
import popupReducer from './slices/popupSlice';
import spinnerReducer from './slices/spinnerSlice';
import userReducer from './slices/userSlice';
import keyboardReducer from './slices/keyboardSlice';
import configReducer from './slices/configSlice';

export const store = configureStore({
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
  reducer: {
    user: userReducer,
    popup: popupReducer,
    spinner: spinnerReducer,
    keyboard: keyboardReducer,
    config: configReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
