import { useState, useContext, createContext, useRef } from 'react';
import { WifiDetails } from './model/WifiDetails';
import { WifiLayout } from './WifiLayout';
import { Button, Col, Container, Form, Modal, Row } from 'react-bootstrap';
import SearchAnimatedSvg from './components/animation/SearchAnimatedSvg';
import styles from './wifi.module.scss';
import PrivacyPolicyFile from 'assets/txt/privacy_policy.txt';
import * as WifiConstants from './WifiConstants';
import { useAsync } from '../../hooks/useAsync';
import classnames from 'classnames';

type WifiContextArgs = {
  wifiDetails: WifiDetails;
  showWifiConnectModal: boolean;
  setShowWifiConnectModal: (show: boolean) => void;
  setWifiDetails: (wifiDetails: WifiDetails) => void;
  privacyPolicyDiv: HTMLDivElement | null;
};

const initialState: WifiContextArgs = {
  wifiDetails: { signal: 0, ssid: '', connected: false },
  showWifiConnectModal: false,
  setShowWifiConnectModal: (show: boolean) => {},
  setWifiDetails: (wifiDetails: WifiDetails) => {},
  privacyPolicyDiv: null,
};

const WifiContext = createContext<WifiContextArgs>(initialState);
export const useWifiContext = () => {
  return useContext(WifiContext);
};

export const WifiProvider = () => {
  const [wifiDetails, setWifiDetails] = useState(initialState.wifiDetails);
  const [showPrivacyPolicyModal, setShowPrivacyPolicyModal] = useState(false);
  const [showWifiConnectModal, setShowWifiConnectModal] = useState(false);
  const [acceptedPrivacyPolicy, setAcceptedPrivacyPolicy] = useState(false);
  const { value: privacyPolicyTxt } = useAsync(() => getPrivacyPolicyTxt());

  const privacyPolicyRef = useRef<HTMLDivElement>(null);

  const args = {
    wifiDetails,
    showWifiConnectModal,
    setShowWifiConnectModal,
    setWifiDetails,
    privacyPolicyDiv: privacyPolicyRef.current,
  };

  const openPrivacyPolicyModal = () => setShowPrivacyPolicyModal(true);
  const closePrivacyPolicyModal = () => setShowPrivacyPolicyModal(false);

  const getPrivacyPolicyTxt = async () => {
    const response = await fetch(PrivacyPolicyFile);
    const text = await response.text();
    return text;
  };

  const updateAcceptedPrivacyPolicy = (newVal: boolean) => {
    setAcceptedPrivacyPolicy(newVal);
    if (privacyPolicyRef.current) {
      let classNamesArr: string[] = privacyPolicyRef.current.className.split(' ');
      if (newVal) {
        classNamesArr = classNamesArr.filter((e) => !e.includes(WifiConstants.REQUIRED_CLASS));
        classNamesArr.push(WifiConstants.CHECKED_CLASS);
      } else classNamesArr = classNamesArr.filter((e) => !e.includes(WifiConstants.CHECKED_CLASS));
      privacyPolicyRef.current.className = classNamesArr.join(' ');
    }
  };

  const generateAcceptPrivacyPolicyModal = () => {
    return (
      <Modal show={showPrivacyPolicyModal} onHide={closePrivacyPolicyModal}>
        <Modal.Header closeButton>
          <Modal.Title>Privacy Policy</Modal.Title>
        </Modal.Header>
        <Modal.Body className={styles.privacyPolicyModalBody}>
          <Form>
            <Form.Group className="mb-3">
              <div>{privacyPolicyTxt}</div>
            </Form.Group>

            <Form.Group className="my-2 text-center">
              <Button
                className={classnames(styles.wifiButton, styles.wifiButtonWidth200)}
                onClick={() => {
                  updateAcceptedPrivacyPolicy(true);
                  setShowPrivacyPolicyModal(false);
                }}
              >
                Accept
              </Button>
              <Button
                className={classnames(styles.wifiButton, styles.wifiButtonWidth200)}
                onClick={() => {
                  updateAcceptedPrivacyPolicy(false);
                  setShowPrivacyPolicyModal(false);
                }}
              >
                Decline
              </Button>
            </Form.Group>
          </Form>
        </Modal.Body>
      </Modal>
    );
  };

  return (
    <>
      <Container>
        <SearchAnimatedSvg className={styles.animatedSearchSvg} />
        <Row>
          <Col className="setup-step-title">Find WiFi Network</Col>
        </Row>
        <Row>
          <Col className="setup-step-subtitle">Connecting to the secure clinic's WiFi is highly recommended.</Col>
        </Row>
        <Row>
          <Col>
            <div ref={privacyPolicyRef} className={styles.privacyPolicyDiv}>
              <input
                type={'checkbox'}
                checked={acceptedPrivacyPolicy}
                onChange={() => updateAcceptedPrivacyPolicy(!acceptedPrivacyPolicy)}
              />
              <span
                className="px-2"
                onClick={openPrivacyPolicyModal}
                style={{ color: 'inherit', fontWeight: 'inherit', textDecoration: 'underline' }}
              >
                Please accept Privacy Policy
              </span>
            </div>
          </Col>
        </Row>
        <Row>
          <Col className="fw-bold">Available Networks</Col>
        </Row>
        <WifiContext.Provider value={args}>
          <WifiLayout />
        </WifiContext.Provider>
      </Container>
      {generateAcceptPrivacyPolicyModal()}
    </>
  );
};
