import addressMarker from 'assets/images/address-marker.svg';
import { useForceUpdate } from 'hooks/useForceUpdate';
import { useShowPopup } from 'hooks/useShowPopup';
import { useSpinner } from 'hooks/useSpinner';
import { Col, Form, InputGroup, Row } from 'react-bootstrap';
import { ProjectMetadata } from 'types/cot.properties.types';
import { concatenateGeolocation, getGeolocation } from 'utils/getGeolocation';

export function SetGeolocation({ projectMetadata }: { projectMetadata: ProjectMetadata }) {
  const showPopup = useShowPopup();
  const showSpinner = useSpinner();
  const forceUpdate = useForceUpdate();

  const setGeolocation = async () => {
    try {
      showSpinner(true);
      const location = await getGeolocation();
      const { latitude, longitude } = location.coords;
      projectMetadata.address!.geolocation = { latitude, longitude };
      forceUpdate();
      showPopup('Location set');
    } catch (error) {
      showPopup('Location set failed');
    } finally {
      showSpinner(false);
    }
  };
  return (
    <Row>
      <Col>
        <Form.Group className="mb-2" controlId="clinicGeolocation">
          <InputGroup>
            <Form.Control
              className="setup-form-field-text"
              type="text"
              placeholder="Geolocation"
              disabled
              defaultValue={concatenateGeolocation(projectMetadata.address?.geolocation)}
            />
            <InputGroup.Text className="address-marker" onClick={setGeolocation}>
              <img alt="address" src={addressMarker} />
            </InputGroup.Text>
          </InputGroup>
        </Form.Group>
      </Col>
    </Row>
  );
}
