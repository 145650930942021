import userIcon from 'assets/images/user_icon.svg';
import { useAppDispatch, useAppSelector } from 'hooks/useApp';
import { useState } from 'react';
import { Button, Col, Offcanvas, Row } from 'react-bootstrap';
import { logoutTheUser, selectUser } from 'store/slices/userSlice';
import styles from './userIcon.module.css';
import { API_MAP, request } from 'services/httpService';
import { UserState, getConfig } from 'store';
import { useNavigate } from 'react-router-dom';
import { routesConstant } from 'routes/routes.const';

export const UserIcon = () => {
  const dispatch = useAppDispatch();
  const [show, setShow] = useState(false);
  const user = useAppSelector(selectUser);
  const config = useAppSelector(getConfig);
  const userLoggedIn: boolean = user.email !== undefined && user.email.length > 0;
  const navigate = useNavigate();
  if (!userLoggedIn) {
    return;
  }

  return (
    <>
      <img src={userIcon} alt="" onClick={() => setShow((prev) => !prev)}></img>
      <Offcanvas
        show={show}
        onHide={() => setShow(false)}
        placement="top"
        style={{ backgroundColor: 'var(--dark-blue)', height: '80px' }}
      >
        <Row className="h-100">
          <Col xs={7} className="mx-3 my-auto" style={{ color: 'var(--white)' }}>
            {user.email}
          </Col>
          <Col xs={4} className="my-auto">
            <Button
              className={styles.logoutBtn}
              onClick={() => {
                dispatch(logoutTheUser());
                setShow((prev) => !prev);
                if (config.isGwDevice) {
                  const emptyUser: UserState = { apiKey: '', company: '', email: '', role: '' };
                  request(API_MAP.storeUser, { payload: emptyUser });
                  navigate(routesConstant.allocateQrCode.absolute());
                }
              }}
            >
              Logout
            </Button>
          </Col>
        </Row>
      </Offcanvas>
    </>
  );
};
