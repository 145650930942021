import { Button, Col, Container, Form, Modal, Row } from 'react-bootstrap';
import { ProjectMetadata } from 'types/cot.properties.types';
import { useRef, useState } from 'react';
import { LinkButton } from 'components/LinkButton';

export const AddNotes = ({ projectMetadata }: { projectMetadata: ProjectMetadata }) => {
  const [showModal, setShowModal] = useState(false);
  const notes = useRef<HTMLTextAreaElement>(null);
  return (
    <>
      <LinkButton prefix={'+'} onClick={() => setShowModal(true)}>
        Add notes
      </LinkButton>
      <Modal show={showModal}>
        <Modal.Body>
          <Container>
            <Form>
              <Row>
                <Col className="setup-step-title mb-4">Clinic notes</Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group className="mb-2" controlId="clinicNotes">
                    <Form.Control ref={notes} as="textarea" rows={3} defaultValue={projectMetadata.note} />
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mt-4 mb-5 text-center">
                <Col>
                  <Button className="outlined-btn me-3" type="button" onClick={() => setShowModal(false)}>
                    Discard
                  </Button>
                  <Button
                    className="filled-btn"
                    onClick={() => {
                      setShowModal(false);
                      projectMetadata.note = notes.current?.value;
                    }}
                  >
                    Save
                  </Button>
                </Col>
              </Row>
            </Form>
          </Container>
        </Modal.Body>
      </Modal>
    </>
  );
};
