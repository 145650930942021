export type Project = {
  name: string;
  id: string;
  description: string;
  company: string;
  metadata?: ProjectMetadata;
};

export type ProjectMetadata = {
  address?: ProjectAddress;
  note?: string;
  contact?: ProjectContact;
  contacts?: Array<ProjectContact>;
  days?: WorkingHours[];
};

export type GwLocation = {
  deviceId: string;
  floor: string;
  room: string;
};

export type WorkingHours = {
  day: string;
  hours: Hours[];
};

export type Hours = {
  from: string;
  to: string;
};

export type ProjectContact = {
  name: string;
  position: string;
  phone: string;
  email: string;
};

export type ProjectAddress = {
  line1: string;
  line2: string;
  geolocation?: Geolocation;
};

export type Geolocation = {
  latitude: number;
  longitude: number;
};

export const projectContactInitValue = (): ProjectContact => {
  return { name: '', phone: '', email: '', position: '' };
};
export type ProjectConfig = {
  clinicUrl: string;
};

export type DeviceUrls = {
  login: string;
};
