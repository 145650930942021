import { useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { LOGIN_DEFAULT_URL } from '../../constants/device.urls.constants';
import { useAppDispatch, useAppSelector } from '../../hooks/useApp';
import { useAsync } from '../../hooks/useAsync';
import { gwService } from '../../services/gw.service';
import { selectUser, loginTheUser, getConfig } from '../../store';

const getLoginUrl = async (isGwDevice: boolean): Promise<string | unknown> => {
  var loginUrl: String = LOGIN_DEFAULT_URL;
  if (isGwDevice) {
    const deviceUrlsLogin = await gwService.getDeviceUrlsLogin();
    loginUrl = deviceUrlsLogin ?? LOGIN_DEFAULT_URL;
  }
  return loginUrl + '?showOnScreenKeyboard';
};
const useRedirect = () => {
  const user = useAppSelector(selectUser);
  const location = useLocation();
  const from: string = location.state?.from?.pathname || '/';
  return { shouldNavigate: user.apiKey?.length > 0, from };
};

export const Login = () => {
  const dispatch = useAppDispatch();
  const config = useAppSelector(getConfig);
  const isGwDevice: boolean = config.isGwDevice;
  const { value: loginUrl } = useAsync(() => getLoginUrl(isGwDevice));
  const { shouldNavigate, from } = useRedirect();

  useEffect(() => {
    if (!loginUrl) return;

    const handleEvent = (event: MessageEvent<any>) => {
      if (!String(loginUrl).startsWith(event.origin)) return;
      const key: string = Object.keys(event.data)[0];
      if (key === 'currentUser') {
        const value = JSON.parse(event.data[key]);
        if (value.verified) dispatch(loginTheUser({ ...value, storeUser: isGwDevice }));
      }
    };
    window.addEventListener('message', handleEvent);
    return () => window.removeEventListener('message', handleEvent);
  }, [loginUrl]);

  if (shouldNavigate) {
    return <Navigate to={from} replace />;
  }

  return (
    <div className="container-iframe">
      <iframe
        className="responsive-iframe"
        title="LoginIframe"
        src={`${loginUrl}`}
        style={{ position: 'fixed', top: '5%' }}
      ></iframe>
    </div>
  );
};
