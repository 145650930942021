import { useEffect, useState } from 'react';

const loadIcon = async (iconPath: string) => {
  return await import('../assets/images/'+iconPath);
};

export const useLoadIcon = (iconPath: string) => {
  let [icon, setIcon] = useState('');
  useEffect(() => {
    loadIcon(iconPath).then(importedIcon => setIcon(importedIcon.default));
  }, [iconPath]);
  return icon;
};
