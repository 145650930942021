import { useAppDispatch, useAppSelector } from './useApp';
import { initTheUser, selectUser } from '../store';
import { useEffect } from 'react';
import { NOT_INITILIZED } from 'constants/string.constants';

export const useInitTheUser = (isGwDevice: boolean) => {
  const dispatch = useAppDispatch();
  const user = useAppSelector(selectUser);
  useEffect(() => {
    if (user.email !== NOT_INITILIZED) {
      return;
    }
    dispatch(initTheUser({ isGwDevice }));
  }, [isGwDevice, dispatch, user]);
};
