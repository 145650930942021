import Keyboard from 'react-simple-keyboard';
import 'react-simple-keyboard/build/css/index.css';
import { useState, useRef, useLayoutEffect, useEffect } from 'react';
import { Button, Col, Container, Modal, Row } from 'react-bootstrap';
import { useShowKeyboard } from 'hooks/useShowKeyboard';
import { useAppSelector } from 'hooks/useApp';
import { initialState, selectKeyboardState } from 'store/slices/keyboardSlice';
import styles from './CustomKeyboard.module.scss';
import './CustomKeyboard.scss';
import { InputPasswordWithEye } from '../InputPasswordWithEye/InputPasswordWithEye.component';
import { InputText } from '../InputText/InputText';
import { getConfig } from '../../store';

export const CustomKeyboard = () => {
  const showKeyboard = useShowKeyboard();
  const { input, setInput, show, isPassword, defaultLayout } = useAppSelector(selectKeyboardState);
  const [innerInput, setInnerInput] = useState('');
  const [layoutName, setLayoutName] = useState('default');
  const keyboardRef = useRef<any>(undefined);
  const inputRef = useRef<HTMLInputElement>(null);

  const config = useAppSelector(getConfig);
  const isGwDevice: boolean = config.isGwDevice;

  const customStyles = {
    '--bs-modal-width': '95vw',
  } as React.CSSProperties;
  useEffect(() => {
    keyboardRef.current?.setInput(input);
    setInnerInput(input);
  }, [input]);

  useEffect(() => {
    if (show) {
      setInnerInput(input);
      if (defaultLayout) setLayoutName(defaultLayout);
    }
  }, [show, defaultLayout, input]);

  useLayoutEffect(() => {
    inputRef.current?.focus();
  });
  const onKeyPress = (button: string) => {
    if (button.includes('{') && button.includes('}')) {
      let newLayout;
      switch (button) {
        case '{shift}':
        case '{default}':
          newLayout = layoutName === 'default' ? 'shift' : 'default';
          break;

        case '{alt}':
          newLayout = layoutName === 'alt' ? 'default' : 'alt';
          break;

        default:
          if (defaultLayout) newLayout = defaultLayout;
          break;
      }
      if (newLayout) setLayoutName(newLayout);
    }
  };

  const layout = {
    // prettier-ignore
    default: [
        'q w e r t y u i o p', 
        'a s d f g h j k l', 
        '{shift} z x c v b n m {bksp}', 
        '{alt} , {space} .'
    ],
    // prettier-ignore
    shift: [
      'Q W E R T Y U I O P', 
      'A S D F G H J K L', 
      '{shift} Z X C V B N M', 
      '{alt} , {space} .'
    ],
    // prettier-ignore
    alt: [
      '1 2 3 4 5 6 7 8 9 0 {bksp}', 
      `@ # $ & * ( ) [ ] ' "`, 
      '% ^ _ - + = / ; : ! ?', 
      '{default} {space} {default}'
    ],
    email: ['q w e r t y u i o p', 'a s d f g h j k l', '{shift} z x c v b n m {bksp}', '{alt} @ {space} .'],
  };
  const display = {
    '{alt}': '.?123',
    '{bksp}': '⌫',
    '{shift}': '⇧',
    '{default}': 'ABC',
    '{space}': ' ',
  };
  const onChangeInput = (event: any) => {
    setInput(event.target.value);
    setInnerInput(event.target.value);
    keyboardRef.current?.setInput(event.target.value);
    setInnerInput(event.target.value);
  };

  const onChangeKeyboard = (input: string) => {
    setInput(input);
    setInnerInput(input);
  };

  return !isGwDevice ? (
    <></>
  ) : (
    <Container>
      <Modal show={show} style={customStyles} className={styles.keyboard_modal}>
        <Modal.Dialog className={styles.modal_dialog}>
          <Modal.Header className="h-20">
            <Container>
              <Row className="justify-content-center">
                <Col xs={8}>
                  {isPassword === true && (
                    <InputPasswordWithEye
                      value={innerInput}
                      id={'id_keyboard_InputPasswordWithEye'}
                      onChange={onChangeInput}
                      inputRef={inputRef}
                      style={{ width: '90%' }}
                    />
                  )}
                  {!isPassword && (
                    <InputText
                      value={innerInput}
                      onChange={onChangeInput}
                      inputRef={inputRef}
                      style={{ width: '90%' }}
                      id={'id_keyboard_InputText'}
                    />
                  )}
                </Col>
                <Col xs={2}>
                  <Button variant="primary" onClick={() => showKeyboard(initialState)}>
                    Ok
                  </Button>
                </Col>
              </Row>
            </Container>
          </Modal.Header>
          <Modal.Body className={styles.myKeyboardContainer}>
            <Keyboard
              layoutName={layoutName}
              display={display}
              layout={layout}
              onChange={onChangeKeyboard}
              keyboardRef={(r) => (keyboardRef.current = r)}
              onKeyPress={onKeyPress}
              preventMouseDownDefault={true}
            />
          </Modal.Body>
        </Modal.Dialog>
      </Modal>
    </Container>
  );
};
