import { QueryClient, useMutation, useQueryClient } from '@tanstack/react-query';
import { useShowPopup } from 'hooks/useShowPopup';
import { cotService } from 'services/cot.service';
import { Project } from 'types/cot.properties.types';
import { isInventoryProject } from 'utils/project.utils';
import { OPTIONAL_DEVICE_BY_ID_KEY } from 'hooks/queries/useGetOptionalDeviceByIdQuery';
import { useSpinner } from 'hooks/useSpinner';
import { useGetFlatProjectsQuery } from 'hooks/queries/useGetFlatProjectsQuery';
import { waitUntil } from 'utils/wait.utils';

class DeviceAllocationLogic {
  invalidateDevice = async (queryClient: QueryClient, deviceId: string, project: Project) => {
    await waitUntil(async () => {
      const device = await cotService.getOptionalDeviceById(deviceId);
      return !device || device.project === project.id;
    });
    queryClient.invalidateQueries([OPTIONAL_DEVICE_BY_ID_KEY]);
  };

  getDeallocateDeviceFunction = (deviceId: string) => {
    const showPopup = useShowPopup();
    const showSpinner = useSpinner();
    const queryClient = useQueryClient();
    const { mutate: deallocateDevice } = useMutation(
      async (project: Project) => {
        showSpinner(true);
        await cotService.deallocateDevice(project.company, project.id, deviceId);
        await this.invalidateDevice(queryClient, deviceId, project);
        showSpinner(false);
        showPopup('Deallocate successfully!');
      },
      {
        onError: (error, variables, context) => {
          showPopup('Failed to deallocate!');
          console.error(error);
          showSpinner(false);
        },
      }
    );
    return deallocateDevice;
  };

  useDeviceAllocationLogic = (deviceId: string) => {
    const showPopup = useShowPopup();
    const { data: flatProjects } = useGetFlatProjectsQuery();
    const deallocateDevice = this.getDeallocateDeviceFunction(deviceId);
    const deallocate = () => {
      const inventory = flatProjects?.find(isInventoryProject);
      if (!inventory) {
        showPopup('Inventory not found!');
        return;
      }
      deallocateDevice(inventory);
    };

    return { deallocate };
  };
}

export const deviceAllocationLogic = new DeviceAllocationLogic();
