import { MouseEventHandler } from 'react';
import { Button } from 'react-bootstrap';

export const LinkButton = ({
  prefix,
  children,
  onClick,
}: {
  prefix?: React.ReactNode;
  children?: React.ReactNode;
  onClick?: MouseEventHandler<HTMLButtonElement> | undefined;
}) => {
  return (
    <Button variant="link" className="setup-add-link me-3" onClick={onClick}>
      {prefix}
      <span className="underline ms-1">{children}</span>
    </Button>
  );
};
