import { WifiNotConnectedAlert } from 'components/Alerts/WifiNotConnectedAlert';
import { NowBrandingLayout } from 'layouts/NowBrandingLayout';
import { GWDashboard } from 'pages/GWDashboard/GWDashboard';
import {
  GWSetupClinicSearch,
  GWSetupSetLocation,
  GWSetupNewClinic,
  GWSetupStart,
  GWSetupLocationDetails,
  GWSetupMachinesRecommendation,
  GWSetupSetMachines,
  GWSetupCompleted,
  ClinicList,
} from 'pages/GWSetupWizard';
import { GWSearchGwBySerial } from 'pages/GWSetupWizard/GwSearchGwBySerial/GWSearchGwBySerial';
import { GearIcon } from 'pages/NavBar/components/GearIcon/GearIcon';
import { UserIcon } from 'pages/NavBar/components/UserIcon/UserIcon';
import { WifiSignalIcon } from 'pages/NavBar/components/WifiSignalIcon/WifiSignalIcon';
import { Navigate, Route, Routes, useSearchParams } from 'react-router-dom';
import { WifiProvider } from '../components/WifiSelectModal/WifiContext';
import { OperatorGuide } from '../pages/OperatorGuide/OperatorGuide';
import { PrivateRoute } from './PrivateRoute';
import { routesConstant } from './routes.const';
import { MenuItem } from './routes.types';
import { ScrollToTop } from './ScrollToTop';
import { ClinicViewIframe } from 'pages/ClinicView/ClinicViewIframe';
import { Login } from 'pages/Login/Login';
import { useSetConfig } from '../hooks/useSetConfig';
import { GwInfo } from 'components/GwInfo/GwInfo';
import { useInitTheUser } from 'hooks/useInitTheUser';
import { useBoardStartRoute } from './hooks/useBoardStartRoute';
import { HomePage } from 'pages/HomePage/HomePage';
import { EditClinic } from 'pages/GWSetupWizard/GWSetupSetLocation/EditClinic';
import { NewClinic } from 'pages/GWSetupWizard/GWSetupNewClinic/NewClinic';
import { RegistrationPage } from '../pages/RegistrationPage/RegistrationPage';

const webIconProvider = (): Array<JSX.Element | undefined> => {
  const menuItems: MenuItem[] = [
    {
      title: 'Home',
      to: routesConstant.home.absolute(),
    },
  ];
  return [UserIcon(), GearIcon(menuItems)];
};

const boardIconProvider = (): Array<JSX.Element | undefined> => {
  const menuItems: MenuItem[] = [
    {
      title: 'WiFi',
      to: routesConstant.wifiConfig.absolute(),
    },
    {
      title: 'Barcode to allocate',
      to: routesConstant.allocateQrCode.absolute(),
    },
    {
      title: 'Clinic view',
      to: routesConstant.clinicViewIframe.absolute(),
    },
    {
      title: 'GW info',
      to: routesConstant.gwInfo.absolute(),
    },
  ];
  return [UserIcon(), WifiSignalIcon(), GearIcon(menuItems)];
};

export const AppRoute = () => {
  const [searchParams] = useSearchParams();
  const isGwDevice = searchParams.has('isGwDevice');
  useSetConfig(isGwDevice);
  useInitTheUser(isGwDevice);
  const boardStartRoute = useBoardStartRoute();

  if (!boardStartRoute) {
    return <></>;
  }

  return (
    <ScrollToTop>
      <Routes>
        <Route
          path="/"
          element={
            <NowBrandingLayout iconsProvider={boardIconProvider}>
              <WifiNotConnectedAlert />
            </NowBrandingLayout>
          }
        >
          <Route
            path="/"
            element={<Navigate to={isGwDevice ? boardStartRoute : routesConstant.home.path()} replace />}
          />
          <Route path="/" element={<PrivateRoute />}>
            <Route path={routesConstant.clinicViewIframe.path()} element={<ClinicViewIframe />} />
          </Route>
          <Route
            path={routesConstant.allocateQrCode.path()}
            element={<OperatorGuide qrUrl="/api/gw/qr/homepage" description="qrHomepageDecription" />}
          />
          <Route path={routesConstant.wifiConfig.path()} element={<WifiProvider />} />
          <Route path={routesConstant.gwInfo.path()} element={<GwInfo />} />
        </Route>

        <Route path="/" element={<NowBrandingLayout iconsProvider={isGwDevice ? boardIconProvider : () => []} />}>
          <Route path="login" element={<Login />} />
          <Route path={routesConstant.registration.path()} element={<RegistrationPage />}></Route>
        </Route>
        <Route path="/" element={<PrivateRoute />}>
          <Route path="/" element={<NowBrandingLayout iconsProvider={webIconProvider} />}>
            <Route path={routesConstant.gwDashboard.path()} element={<GWDashboard />} />
            <Route path={routesConstant.home.path()} element={<HomePage />}></Route>
            <Route path={routesConstant.clinicList.path()} element={<ClinicList title="Clinic list" />}></Route>
            <Route path={routesConstant.editClinic.path()} element={<EditClinic title="Edit clinic" />}></Route>
            <Route path={routesConstant.newClinic.path()} element={<NewClinic />}></Route>
          </Route>

          <Route path="setup" element={<NowBrandingLayout iconsProvider={webIconProvider} />}>
            <Route path={routesConstant.gwSearchGwBySerial.path()} element={<GWSearchGwBySerial />} />
            <Route path={routesConstant.gwSetupStart.path()} element={<GWSetupStart />} />
            <Route path={routesConstant.gwSetupClinicSearch.path()} element={<GWSetupClinicSearch />} />
            <Route path={routesConstant.gwSetupNewClinic.path()} element={<GWSetupNewClinic />} />
            <Route path={routesConstant.gwSetupSetLocation.path()} element={<GWSetupSetLocation />} />
            <Route path={routesConstant.gwSetupLocationDetails.path()} element={<GWSetupLocationDetails />} />
            <Route
              path={routesConstant.gwSetupMachinesRecommendation.path()}
              element={<GWSetupMachinesRecommendation />}
            />
            <Route path={routesConstant.gwSetupSetMachines.path()} element={<GWSetupSetMachines />} />
            <Route path={routesConstant.gwSetupCompleted.path()} element={<GWSetupCompleted />} />
          </Route>
        </Route>
      </Routes>
    </ScrollToTop>
  );
};
