import { generatePath } from 'react-router-dom';

type ParamsType = {
  [key: string]: string;
};

class RoutItem {
  url: string;
  constructor(url: string) {
    this.url = url;
  }

  path(params?: ParamsType): string {
    if (params) {
      return generatePath(this.url, params);
    }
    return this.url;
  }

  absolute(params?: ParamsType) {
    return '/' + this.path(params);
  }

  relatirve(params?: ParamsType) {
    return '../' + this.path(params);
  }
}

class RoutesConstant {
  home = new RoutItem('home');
  registration = new RoutItem('registration/deviceId/:deviceId');

  allocateQrCode = new RoutItem('allocate-qr-code');
  wifiConfig = new RoutItem('wifi-config');

  clinicViewIframe = new RoutItem('clinic-view-iframe');
  gwInfo = new RoutItem('gw-info');
  gwSearchGwBySerial = new RoutItem('search-gw-by-serial');
  gwSetupSetLocation = new RoutItem('set-gw-location/companyId/:companyId/projectId/:projectId/deviceId/:deviceId');
  gwSetupLocationDetails = new RoutItem(
    'gw-location-details/companyId/:companyId/projectId/:projectId/deviceId/:deviceId'
  );
  gwSetupStart = new RoutItem('start/deviceId/:deviceId');
  gwSetupClinicSearch = new RoutItem('search-clinic/deviceId/:deviceId');
  gwSetupMachinesRecommendation = new RoutItem(
    'machines-recommend/companyId/:companyId/projectId/:projectId/deviceId/:deviceId'
  );
  gwSetupSetMachines = new RoutItem('set-machines/companyId/:companyId/projectId/:projectId/deviceId/:deviceId');
  gwSetupNewClinic = new RoutItem('new-clinic/deviceId/:deviceId');
  gwSetupCompleted = new RoutItem('completed/companyId/:companyId/projectId/:projectId/deviceId/:deviceId');
  gwDashboard = new RoutItem('dashboard/deviceId/:deviceId');

  clinicList = new RoutItem('clinics');
  editClinic = new RoutItem('clinics/edit/companyId/:companyId/projectId/:projectId');
  newClinic = new RoutItem('clinics/new');
}

export const routesConstant = new RoutesConstant();
