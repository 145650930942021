import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { loginTheUser } from './userSlice';

export interface PopupState {
  show: boolean;
  message: string;
}

const initialState: PopupState = {
  show: false,
  message: '',
};

export const popupSlice = createSlice({
  initialState,
  name: 'popup',
  reducers: {
    showPopup: (state, action: PayloadAction<string>) => {
      state.show = true;
      state.message = action.payload;
    },
    hidePopup: (state) => {
      state.show = false;
      state.message = '';
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loginTheUser.rejected, (state) => {
      state.show = true;
      state.message = 'Failed to login';
    });
  },
});

export const { showPopup, hidePopup } = popupSlice.actions;
export const selectPopupState = (state: RootState) => state.popup;
export default popupSlice.reducer;
