import { Col, Row } from 'react-bootstrap';
import { GwLocation } from 'types/cot.properties.types';

const LocationItem = ({ header, content }: { header: string; content?: string }) => {
  if (!content) {
    return <></>;
  }
  return (
    <Col className="d-flex">
      <span className="setup-label">{header}</span>
      {content}
    </Col>
  );
};

export const GwLocationInfo = ({ gwLocations, deviceId }: { gwLocations?: GwLocation[]; deviceId: string }) => {
  const gwLocation = gwLocations?.find((location) => location.deviceId === deviceId);
  if (!gwLocation || (!gwLocation.floor.length && !gwLocation.room.length)) {
    return <></>;
  }
  return (
    <>
      <Row>
        <Col className="setup-section-title font-weight-bold fs-20 mt-4">Gateway Info</Col>
      </Row>
      <Row>
        <LocationItem header={'Floor'} content={gwLocation?.floor} />
        <LocationItem header={'Room'} content={gwLocation?.room} />
      </Row>
    </>
  );
};
