import iTooltip from 'assets/images/i_tooltip.svg';
import { useRef, useState } from 'react';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import { InfoPopup } from './InfoPopup';
import { SerialPorts } from './SerialMachinesModal';

export const SerialMachineConfiguration = () => {
  const [show, setShow] = useState(false);
  const target = useRef(null);

  return (
    <>
      <Row>
        <Col className="d-flex mt-4 mb-2 align-items-center">
          <div className="setup-section-title underline flex-grow-1">Serial Ports</div>
          <Button variant="link" ref={target}>
            <img
              alt="action"
              src={iTooltip}
              onClick={() => {
                setShow(!show);
              }}
            />
          </Button>
        </Col>
      </Row>
      <Modal size="lg" show={show} onHide={() => setShow(!show)} rootClose={true} rootCloseEvent={'click'}>
        <Modal.Body>
          <InfoPopup machineType={['Atos', 'Midmark']} clicked={() => setShow(!show)} />
        </Modal.Body>
      </Modal>
      <SerialPorts />
    </>
  );
};
