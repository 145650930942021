import { Navigate, Outlet, useLocation } from 'react-router';
import { useAppSelector } from 'hooks/useApp';
import { selectUser } from 'store';

export const PrivateRoute = () => {
  const user = useAppSelector(selectUser);
  let location = useLocation();
  if (user.apiKey === '') {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }
  return <Outlet />;
};
