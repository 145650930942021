import { useAsync } from 'hooks/useAsync';
import { Alert } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import { routesConstant } from 'routes/routes.const';
import { gwService } from 'services/gw.service';

export const WifiNotConnectedAlert = () => {
  const location = useLocation();
  const { value: wifiList } = useAsync(() => gwService.getWifiList(), { interval: 5000 });
  const connected = wifiList?.some((wifi) => wifi.connected) ?? true;

  if (connected || location.pathname === routesConstant.wifiConfig.absolute()) {
    return <></>;
  }

  return (
    <Alert variant={'danger'}>
      The WiFi is not connected. Please go to{' '}
      <Link to={routesConstant.wifiConfig.absolute()} state={{ from: location }}>
        WiFi config
      </Link>
      to connect the network.
    </Alert>
  );
};
