import { useAppDispatch } from './useApp';
import { showPopup } from '../store/slices/popupSlice';
import { handleAxiosError } from './popup.error.handlers/handleAxiosError';

export const useShowPopup = () => {
  const dispatch = useAppDispatch();
  return (message: string) => dispatch(showPopup(message));
};

export function usePopupDecorator<T extends any[], R>(fn: (...args: T) => Promise<R>) {
  const showPopup = useShowPopup();
  return async (...args: T) => {
    try {
      return await fn(...args);
    } catch (e) {
      const handlers = [handleAxiosError, handleDefault];
      handlers.some((handler) => handler(e, showPopup));
      throw e;
    }
  };
}

function handleDefault(e: unknown, showPopup: (message: string) => void): boolean {
  showPopup('An error apeared');
  return true;
}
