import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';

export interface ConfigState {
  isGwDevice: boolean;
}
export const initialState: ConfigState = {
  isGwDevice: false,
};

export const configSlice = createSlice({
  initialState,
  name: 'Config',
  reducers: {
    setConfig: (state, action: PayloadAction<ConfigState>) => {
      return action.payload;
    },
  },
});
export const { setConfig } = configSlice.actions;
export const getConfig = (state: RootState) => state.config;

export default configSlice.reducer;
