import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { ProjectContact } from 'types/cot.properties.types';

export const ContactList = ({ contacts, contact }: { contacts: Array<ProjectContact>; contact: ProjectContact }) => {
  if (!contact) {
    return <></>;
  }
  const contactsView = [contact, ...contacts];
  return (
    <>
      {contactsView.map((contact, i) => (
        <React.Fragment key={i}>
          <Row>
            <Col className="d-flex">
              <span className="setup-label">Name</span>
              {contact.name}
            </Col>
          </Row>
          <Row>
            <Col className="d-flex">
              <span className="setup-label">Email</span>
              {contact.email}
            </Col>
          </Row>
          <Row>
            <Col className="d-flex">
              <span className="setup-label">Title</span>
              {contact.position}
            </Col>
          </Row>
          <Row>
            <Col className="d-flex">
              <span className="setup-label">Phone</span>
              {contact.phone}
            </Col>
          </Row>
        </React.Fragment>
      ))}
    </>
  );
};
