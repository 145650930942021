import { useQuery } from '@tanstack/react-query';
import { generateQueryKey } from './query.key.generator';
import { cotService } from 'services/cot.service';
import { CotProject } from 'types/cot.types';
import { GwLocation, ProjectMetadata } from 'types/cot.properties.types';

export const GET_PROJECT_DATA = generateQueryKey();

export const useGetProjectDataQuery = (
  companyId: string,
  projectId: string
): {
  projectMetadata?: ProjectMetadata;
  project?: CotProject;
  gwLocations?: GwLocation[];
  loading: boolean;
} => {
  const response = useQuery([GET_PROJECT_DATA, companyId, projectId], () =>
    Promise.all([
      cotService.getProjectProperty<ProjectMetadata>(companyId!, projectId!, 'meta.data'),
      cotService.getProjectProperty<GwLocation[]>(companyId!, projectId!, 'gw.location'),
      cotService.getProject(companyId!, projectId!),
    ])
  );

  const [projectMetadata, gwLocations, project] = response.data ?? [];
  return { projectMetadata: projectMetadata, gwLocations, project, loading: response.isLoading };
};
