import { useQueryClient } from '@tanstack/react-query';
import { GET_SERIAL_PORT_CONFIGS } from 'constants/queryKeys';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { cotService } from 'services/cot.service';
import styles from './serial.machines.module.scss';
import { SerialDropDownMachineList } from './SerialDropDownMachineList';
import { useSpinner } from 'hooks/useSpinner';

export const SerialMachineRemoveReplace = (props: { port: string; clicked: () => void }) => {
  const { deviceId, projectId } = useParams();
  const queryClient = useQueryClient();
  const [showConfig, setShowConfig] = useState(false);
  const showSpinner = useSpinner();

  const handleButtonRemoveClick = async (port: string) => {
    try {
      showSpinner(true);
      const serialPortConfigs = await cotService.getSerialPortConfigs(deviceId!);
      let newConfig = serialPortConfigs?.filter((config) => config.portName !== port);
      await cotService.setSerialPortConfigs(newConfig!, deviceId!);
      cotService.setDeviceCommand(projectId!, deviceId!, 'sys___actions', 'config');
      queryClient.invalidateQueries([GET_SERIAL_PORT_CONFIGS]);
    } finally {
      showSpinner(false);
    }
  };

  return (
    <>
      <div className={styles.removeReplaceChild}>
        <div
          onClick={() => {
            handleButtonRemoveClick(props.port);
            setShowConfig(false);
            props.clicked();
          }}
        >
          Remove
        </div>
        <hr />
        <div
          onClick={() => {
            setShowConfig(!showConfig);
          }}
        >
          Replace
        </div>
      </div>
      <SerialDropDownMachineList port={props.port} showConfig={showConfig} setShowConfig={() => setShowConfig(false)} />
    </>
  );
};
