import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { AddNotes } from '../GWSetupNewClinic/AddNotes';
import { AddWorkingHours } from '../GWSetupNewClinic/AddWorkingHours';
import { ContactPerson } from 'components/ContactPerson/ContactPerson';
import { useParams } from 'react-router-dom';
import { useNavigateWithSearchParams } from 'hooks/useNavigateWithSearchParams';
import { GwLocation, ProjectMetadata, projectContactInitValue } from 'types/cot.properties.types';
import { useRef } from 'react';
import { useForm } from 'react-hook-form';
import { cotService } from 'services/cot.service';
import { routesConstant } from 'routes/routes.const';
import { useSpinnerDecorator, useSpinnerEffect } from 'hooks/useSpinner';
import { GET_PROJECT_DATA, useGetProjectDataQuery } from 'hooks/queries/useGetProjectDataQuery';
import { FLAT_PROJECTS_KEY } from 'hooks/queries/useGetFlatProjectsQuery';
import { useQueryClient } from '@tanstack/react-query';
import { ClinicView } from './ClinicView';
import { usePopupDecorator } from 'hooks/useShowPopup';

export function EditClinic({ title }: { title: string }) {
  const queryClient = useQueryClient();
  const { projectId, companyId, deviceId } = useParams();
  const navigate = useNavigateWithSearchParams();
  const projectMetadataRef = useRef<ProjectMetadata | undefined>(undefined);
  const gwLocationsRef = useRef<GwLocation[] | undefined>(undefined);
  const {
    register,
    formState: { errors },
  } = useForm();
  const { projectMetadata, gwLocations, project, loading } = useGetProjectDataQuery(companyId!, projectId!);
  const projectName = useRef<string>('');
  projectName.current = projectName.current == '' ? project?.name ?? '' : projectName.current;
  const submit = async () => {
    await cotService.editProject(project?.company!, project?.id!, projectName.current!);
    await Promise.all([
      cotService.setProjectProperty<GwLocation[]>(companyId!, projectId!, 'gw.location', gwLocationsRef.current!),
      cotService.setProjectProperty<ProjectMetadata>(companyId!, projectId!, 'meta.data', projectMetadataRef.current!),
    ]);
    await queryClient.invalidateQueries([GET_PROJECT_DATA]);
    await queryClient.invalidateQueries([FLAT_PROJECTS_KEY]);
    navigate(
      deviceId
        ? routesConstant.gwSetupLocationDetails.relatirve({
            companyId: companyId!,
            projectId: projectId!,
            deviceId: deviceId!,
          })
        : '/home'
    );
  };
  const decorateSubmit = usePopupDecorator(useSpinnerDecorator(submit));
  useSpinnerEffect(loading);
  if (loading) {
    return <></>;
  }
  projectMetadataRef.current ??= {
    ...projectMetadata,
    contacts: projectMetadata?.contacts ?? [],
    contact: projectMetadata?.contact ?? projectContactInitValue(),
  };
  gwLocationsRef.current = gwLocations ?? [{ deviceId: deviceId!, floor: '', room: '' }];
  return (
    <Container>
      <Form>
        <Row>
          <Col className="setup-step-title mb-4">{title}</Col>
        </Row>
        <ClinicView
          projectName={projectName}
          project={project}
          projectMetadata={projectMetadataRef.current}
          errors={errors}
          register={register}
        ></ClinicView>
        <Row>
          <Col>
            <Form.Group>
              <AddNotes projectMetadata={projectMetadataRef.current} />
              <AddWorkingHours projectMetadata={projectMetadataRef.current} />
            </Form.Group>
          </Col>
        </Row>
        <ContactPerson
          contact={projectMetadataRef.current!.contact!}
          register={register}
          errors={errors}
          errClassName=""
        ></ContactPerson>
        <Row className="mt-4 mb-5 text-center">
          <Col>
            <Button
              className="outlined-btn me-3"
              type="button"
              onClick={async () => {
                await queryClient.invalidateQueries([GET_PROJECT_DATA]);
                navigate(
                  deviceId
                    ? routesConstant.gwSetupClinicSearch.relatirve({
                        deviceId,
                      })
                    : routesConstant.clinicList.absolute()
                );
              }}
            >
              Discard
            </Button>
            <Button className="filled-btn" onClick={decorateSubmit}>
              Save
            </Button>
          </Col>
        </Row>
      </Form>
    </Container>
  );
}
