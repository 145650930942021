import { Col, Row } from 'react-bootstrap';
import { CotDevice } from 'types/cot.types';

export const ChildrenMachine = ({ deviceChildren }: { deviceChildren?: CotDevice[] }) => {
  return (
    <>
      {deviceChildren?.map((deviceChild) => (
        <Row key={deviceChild.id} className="h-70 mb-2">
          <Col className="d-flex align-items-center">
            <div className="machine-icon"></div>
            <div className="d-flex flex-column flex-grow-1">
              <div className="setup-section-title">{deviceChild.name}</div>
              <div className="setup-section-subtitle fs-14">
                {deviceChild.metaDeviceData?.serial_number}&nbsp;|&nbsp;{deviceChild.metaDeviceData?.model_name}
              </div>
            </div>
          </Col>
        </Row>
      ))}
    </>
  );
};
