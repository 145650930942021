import { Button, Col, Collapse, Container, Form, Modal, Row, Stack } from 'react-bootstrap';
import { ProjectMetadata, WorkingHours } from 'types/cot.properties.types';
import { useState, useRef, useEffect } from 'react';
import { LinkButton } from 'components/LinkButton';

const daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

const FAULT_TIME = '00:00';

const DayCollapse = ({ day, workingHours }: { day: string; workingHours: WorkingHours[] }) => {
  const [open, setOpen] = useState(false);
  const [from, setFrom] = useState(FAULT_TIME);
  const [to, setTo] = useState(FAULT_TIME);

  return (
    <>
      <Row>
        <Col>
          <LinkButton onClick={() => setOpen((prev) => !prev)}>{`${open ? '-' : '+'} ${day}`}</LinkButton>
        </Col>
      </Row>
      <Row>
        <Collapse in={open}>
          <div>
            <Row>
              <Col>
                From:
                <Form.Control value={from} type="time" onChange={(e) => setFrom(e.target.value)} />
              </Col>
              <Col>
                To:
                <Form.Control value={to} type="time" onChange={(e) => setTo(e.target.value)} />
              </Col>
              <Col className="align-self-end">
                <Button
                  onClick={() => {
                    let hours = workingHours.find((workingHour) => workingHour.day === day)?.hours;
                    if (!hours) {
                      hours = [];
                      workingHours.push({ day, hours });
                    }
                    hours.push({ from, to });
                    setFrom(FAULT_TIME);
                    setTo(FAULT_TIME);
                  }}
                >
                  Add
                </Button>
              </Col>
            </Row>
            {workingHours
              .find((workingHour) => workingHour.day === day)
              ?.hours.map((hour, index) => (
                <Row key={index}>
                  <Col>
                    <b>From:</b> {hour.from} <b>To:</b> {hour.to}
                  </Col>
                </Row>
              ))}
          </div>
        </Collapse>
      </Row>
    </>
  );
};

const useCloneWorkingHours = (showModal: boolean, projectMetadata: ProjectMetadata): WorkingHours[] | undefined => {
  const clonedDays = useRef<WorkingHours[]>();
  useEffect(() => {
    if (!showModal) {
      clonedDays.current = undefined;
    }
  }, [showModal]);
  if (!clonedDays.current) {
    clonedDays.current =
      projectMetadata.days?.map((day) => {
        return {
          ...day,
          hours: day.hours.map((hour) => {
            return { ...hour };
          }),
        };
      }) ?? [];
  }
  return clonedDays.current;
};

export const AddWorkingHours = ({ projectMetadata }: { projectMetadata: ProjectMetadata }) => {
  const [showModal, setShowModal] = useState(false);
  const clonedDays = useCloneWorkingHours(showModal, projectMetadata);

  return (
    <>
      <LinkButton prefix={'+'} onClick={() => setShowModal(true)}>
        Add working hours
      </LinkButton>
      <Modal show={showModal}>
        <Modal.Body>
          <Container>
            <Form>
              <Row>
                <Col className="setup-step-title mb-4">Add working hours</Col>
              </Row>
              <Row>
                <Col>
                  {daysOfWeek.map((day) => (
                    <DayCollapse day={day} key={day} workingHours={clonedDays ?? []} />
                  ))}
                </Col>
              </Row>
              <Row className="mt-4 mb-5 text-center">
                <Col>
                  <Button className="outlined-btn me-3" type="button" onClick={() => setShowModal(false)}>
                    Discard
                  </Button>
                  <Button
                    className="filled-btn"
                    onClick={() => {
                      setShowModal(false);
                      projectMetadata.days = clonedDays;
                    }}
                  >
                    Save
                  </Button>
                </Col>
              </Row>
            </Form>
          </Container>
        </Modal.Body>
      </Modal>
    </>
  );
};
