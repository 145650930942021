import { Button, Col, Container, Form, InputGroup, Row } from 'react-bootstrap';
import { CompanySelect } from './CompanySelect';
import { ContactPerson } from 'components/ContactPerson/ContactPerson';
import { useShowPopup } from 'hooks/useShowPopup';
import { useSpinner } from 'hooks/useSpinner';
import { useNavigateWithSearchParams } from 'hooks/useNavigateWithSearchParams';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useRef } from 'react';
import { GwLocation, ProjectConfig, ProjectMetadata, projectContactInitValue } from 'types/cot.properties.types';
import { cotService } from 'services/cot.service';
import { CLINIC_DEFAULT_URL } from 'constants/project.config.constants';
import { routesConstant } from 'routes/routes.const';
import { HIGHLIGHTED_FIELDS_REQUIRED_MSG } from 'constants/string.constants';
import { AddNotes } from './AddNotes';
import { AddWorkingHours } from './AddWorkingHours';
import { EditClinicInfo } from 'components/EditClinicInfo/EditClinicInfo';

export function NewClinic() {
  const ERR_CLASS_NAME = 'error-border';
  const showPopup = useShowPopup();
  const showSpinner = useSpinner();
  const navigate = useNavigateWithSearchParams();
  const { deviceId } = useParams();

  const {
    register,
    handleSubmit,
    clearErrors,
    formState: { errors },
  } = useForm();

  const projectName = useRef('');
  const selectedCompanyId = useRef('');
  const projectMetadata = useRef<ProjectMetadata>({
    contacts: [],
    address: { line1: '', line2: '' },
    contact: projectContactInitValue(),
  });

  const gwLocations = useRef<GwLocation[]>([{ deviceId: deviceId!, floor: '', room: '' }]);

  const createNewProject = async () => {
    try {
      showSpinner(true);
      const companyId = selectedCompanyId.current;
      const project = await cotService.createProject(companyId, projectName.current);
      await Promise.all([
        cotService.setProjectProperty<ProjectConfig>(companyId!, project.id, 'project.config', {
          clinicUrl: CLINIC_DEFAULT_URL,
        }),
        cotService.setProjectProperty<ProjectMetadata>(
          project.company,
          project.id,
          'meta.data',
          projectMetadata.current
        ),
        cotService.setProjectProperty<GwLocation[]>(project.company, project.id, 'gw.location', gwLocations.current),
      ]);
      navigate(
        deviceId
          ? routesConstant.gwSetupLocationDetails.relatirve({
              companyId: companyId,
              projectId: project.id,
              deviceId: deviceId,
            })
          : routesConstant.editClinic.absolute({ companyId: companyId, projectId: project.id })
      );
    } catch (error) {
      console.error(error);
      showPopup('Project creation failed');
    } finally {
      showSpinner(false);
    }
  };
  const onSubmit = async () => {
    await createNewProject();
  };
  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Container>
        <>
          <Row>
            <Col className="setup-step-title mb-4">Create New Clinic</Col>
          </Row>
          <Row>
            {Object.values(errors)?.filter((e) => e?.type === 'required').length > 0 && (
              <span className="text-sm error-text">{HIGHLIGHTED_FIELDS_REQUIRED_MSG}</span>
            )}
          </Row>
          <CompanySelect
            selectedCompanyId={selectedCompanyId}
            register={register}
            errClassName={ERR_CLASS_NAME}
            errors={errors}
            clearErrors={clearErrors}
          ></CompanySelect>
          <EditClinicInfo
            projectName={projectName}
            projectMetadata={projectMetadata.current}
            register={register}
            errors={errors}
          ></EditClinicInfo>
          <Row>
            <Col>
              <Form.Group>
                <AddNotes projectMetadata={projectMetadata.current} />
                <AddWorkingHours projectMetadata={projectMetadata.current} />
              </Form.Group>
            </Col>
          </Row>
          <ContactPerson
            contact={projectMetadata.current.contact!}
            register={register}
            errors={errors}
            errClassName={ERR_CLASS_NAME}
          ></ContactPerson>

          <Row className="mt-4 mb-5 text-center">
            <Col>
              <Button
                className="outlined-btn me-3"
                type="button"
                onClick={() => navigate(routesConstant.clinicList.absolute())}
              >
                Discard
              </Button>
              <Button type="submit" className="filled-btn">
                Save
              </Button>
            </Col>
          </Row>
        </>
      </Container>
    </Form>
  );
}
