import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { API_MAP, request } from 'services/httpService';
import { RootState } from '../store';
import { NOT_INITILIZED } from 'constants/string.constants';
import { gwService } from 'services/gw.service';

export interface COTUserResponse {
  apikey: string;
  company: string;
  role: string;
  verified: number;
}

export interface UserState {
  email: string;
  apiKey: string;
  company: string;
  role: string;
}

const getStoredUser = () => {
  try {
    return localStorage.getItem('userDetails');
  } catch (err) {
    console.error(err);
  }
};

export const userInitialState: UserState = {
  email: NOT_INITILIZED,
  apiKey: '',
  company: '',
  role: '',
};
type LoginTheUserParam = {
  apikey: string;
  company: string;
  role: string;
  verified: string;
  username: string;
  storeUser: boolean;
};

export const initTheUser = createAsyncThunk<UserState | undefined, { isGwDevice: boolean }, { state: RootState }>(
  'user/init',
  async (params) => {
    if (!params.isGwDevice) {
      const storedUser = getStoredUser();
      return storedUser ? JSON.parse(storedUser) : { ...userInitialState, email: '' };
    }
    const deviceInformation = await gwService.getDeviceInformation();
    return deviceInformation.loggedOnUser;
  }
);

export const loginTheUser = createAsyncThunk<UserState, LoginTheUserParam, { state: RootState }>(
  'user/login',
  async (param: LoginTheUserParam) => {
    const userState: UserState = {
      apiKey: param.apikey,
      company: param.company,
      role: param.role,
      email: param.username,
    };
    if (param.storeUser) {
      await request(API_MAP.storeUser, { payload: userState });
    }
    return userState;
  }
);

export const userSlice = createSlice({
  initialState: userInitialState,
  name: 'user',
  reducers: {
    logoutTheUser: () => {
      const newState = { apiKey: '', company: '', email: '', role: '' };
      localStorage.setItem('userDetails', JSON.stringify({ ...newState }));
      return newState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(initTheUser.fulfilled, (state, action) => {
        return action.payload;
      })
      .addCase(loginTheUser.pending, (state) => {
        console.log('login pending');
      })
      .addCase(loginTheUser.fulfilled, (state, action) => {
        localStorage.setItem('userDetails', JSON.stringify(action.payload));
        return action.payload;
      })
      .addCase(loginTheUser.rejected, (state) => {
        console.log('login rejected');
      });
  },
});

export const { logoutTheUser } = userSlice.actions;
export const selectUser = (state: RootState) => state.user;
export default userSlice.reducer;
