import { Col, Row } from 'react-bootstrap';
import { CotProject } from 'types/cot.types';
import { concatAddress } from 'utils/concatAddress';
import { ProjectMetadata } from 'types/cot.properties.types';

const ProjectName = ({ project }: { project: CotProject | undefined }) => {
  return (
    <Row>
      <Col className="d-flex">
        <div className="fs-30">{project?.name}</div>
      </Col>
    </Row>
  );
};

export const Note = ({ projectMetadata }: { projectMetadata?: ProjectMetadata }) => {
  if (!projectMetadata?.note) {
    return <></>;
  }
  return (
    <Row>
      <Col className="d-flex">{`* ${projectMetadata?.note}`}</Col>
    </Row>
  );
};

const Phone = ({ projectMetadata }: { projectMetadata?: ProjectMetadata }) => {
  return (
    <Row>
      <Col className="d-flex">
        <span className="setup-label">Phone</span>
        {projectMetadata?.contact?.phone}
      </Col>
    </Row>
  );
};

export const ClinicDetails = ({
  projectMetadata,
  project,
}: {
  projectMetadata?: ProjectMetadata;
  project?: CotProject;
}) => {
  return (
    <>
      <ProjectName project={project}></ProjectName>
      <Row>
        <Col>{concatAddress(projectMetadata?.address)}</Col>
      </Row>
      <Phone projectMetadata={projectMetadata}></Phone>
      <Note projectMetadata={projectMetadata} />
    </>
  );
};
