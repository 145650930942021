import { useAsync } from 'hooks/useAsync';
import { Col, Container, ListGroup, Row } from 'react-bootstrap';
import { WifiDetails } from './model/WifiDetails';
import { WifiItem } from './WifiItem';
import { WifiConnectModal } from './WifiConnectModal';
import { gwService } from 'services/gw.service';

export const WifiLayout = () => {
  const { value: wifiList } = useAsync(() => gwService.getWifiList(), { interval: 5000 });
  return (
    <>
      <Container>
        <Row>
          <Col lg="5">
            <ListGroup className="pt-4">
              {wifiList?.map((wifiDetails: WifiDetails) => (
                <WifiItem key={wifiDetails.ssid} {...wifiDetails} />
              ))}
            </ListGroup>
          </Col>
        </Row>
      </Container>
      <WifiConnectModal />
    </>
  );
};
