import { Button } from 'react-bootstrap';

const MidmarkDesc = () => {
  return (
    <>
      <h1>Midmark Machines:</h1>
      <h3>
        Please connect the serial cable to the machine and after you select it, press start (run cycle) for 10 seconds
        and cancel it.
      </h3>
      <br></br>
    </>
  );
};

const AtosDesc = () => {
  return (
    <>
      <h1>Atos Machines:</h1>
      <h3>
        Please connect the serial cable to the machine, <br />
        And Set machine external serial port to Lan mode. <br />
        Steps:
        <br />- Press PRG (Menu) button for 5 seconds.
        <br /> - Enter the Super User password (6 times P1 button if not changed). <br />- press the START button under
        GENERAL. <br />- Navigate (press start button) until you reach Device 1: and set it to 2 = LAN (by clicking on
        P1 button twice).
        <br />- Assign the port to Atos machine.
      </h3>
      <br />
    </>
  );
};

const machineDesc: { [key: string]: JSX.Element } = {
  Midmark: <MidmarkDesc />,
  Atos: <AtosDesc />,
};

export const InfoPopup = (props: { machineType: string[]; clicked: () => void }) => {
  return (
    <>
      <div className="popup">
        <div className="popup-content">
          {props.machineType.map((m) => machineDesc[m])}
          <Button className="filled-btn" onClick={() => props.clicked()}>
            Close
          </Button>
        </div>
      </div>
    </>
  );
};
