import { ClinicDetails } from 'components/ClinicDetails/ClinicDetails';
import { ContactList } from 'components/ContactList/ContactList';
import { useAsync } from 'hooks/useAsync';
import { useSpinnerEffect } from 'hooks/useSpinner';
import { useShowPopup } from 'hooks/useShowPopup';
import { useEffect } from 'react';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { useParams, useSearchParams } from 'react-router-dom';
import { routesConstant } from 'routes/routes.const';
import { cotService } from 'services/cot.service';
import { GWSetupProgressBar } from '../components/GWSetupProgressBar/GWSetupProgressBar';
import { projectContactInitValue } from 'types/cot.properties.types';
import { GwLocationInfo } from 'components/GwLocationInfo/GwLocationInfo';
import { OPTIONAL_DEVICE_BY_ID_KEY, useGetOptionalDeviceByIdQuery } from 'hooks/queries/useGetOptionalDeviceByIdQuery';
import { useQueryClient } from '@tanstack/react-query';
import { useNavigateWithSearchParams } from 'hooks/useNavigateWithSearchParams';
import { isAllocated } from 'utils/device.utils';
import { useGetProjectDataQuery } from 'hooks/queries/useGetProjectDataQuery';

const nextPathname = (
  searchParams: URLSearchParams,
  companyId: string,
  projectId: string,
  deviceId: string
): string => {
  return searchParams.get('model')
    ? routesConstant.gwSetupCompleted.relatirve({
        companyId: companyId!,
        projectId: projectId!,
        deviceId: deviceId!,
      })
    : routesConstant.gwSetupMachinesRecommendation.relatirve({
        deviceId,
        companyId,
        projectId,
      });
};

const useNavigateToNextPathname = (companyId: string, projectId: string, deviceId: string) => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigateWithSearchParams();
  return () => navigate(nextPathname(searchParams, companyId, projectId, deviceId));
};

const useAllocateDevice = (companyId: string, projectId: string, deviceId: string) => {
  const queryClient = useQueryClient();
  const { status: allocateStatus, execute: allocateDevice } = useAsync<void>(
    () => cotService.allocateGateway(companyId!, projectId!, deviceId!),
    { immediate: false }
  );
  useSpinnerEffect(allocateStatus === 'pending');
  const showPopup = useShowPopup();
  const navigate = useNavigateToNextPathname(companyId, projectId, deviceId);
  useEffect(() => {
    if (allocateStatus === 'success') {
      queryClient.invalidateQueries([OPTIONAL_DEVICE_BY_ID_KEY]);
      navigate();
    }
    if (allocateStatus === 'error') {
      showPopup('Failed to allocate!');
    }
  }, [allocateStatus, deviceId, companyId, projectId, navigate, showPopup, queryClient]);

  return allocateDevice;
};

const AllocateButton = () => {
  const { projectId, companyId, deviceId } = useParams();
  const allocateDevice = useAllocateDevice(companyId!, projectId!, deviceId!);
  const { data: device } = useGetOptionalDeviceByIdQuery(deviceId!);
  const navigate = useNavigateToNextPathname(companyId!, projectId!, deviceId!);

  if (isAllocated(device)) {
    return (
      <Button className="filled-btn" onClick={() => navigate()}>
        Continue
      </Button>
    );
  }

  return (
    <Button className="filled-btn" onClick={() => allocateDevice()}>
      Allocate
    </Button>
  );
};

export const GWSetupLocationDetails = () => {
  const { projectId, companyId, deviceId } = useParams();
  const { projectMetadata, project, gwLocations } = useGetProjectDataQuery(companyId!, projectId!);
  const navigate = useNavigateWithSearchParams();
  return (
    <>
      <GWSetupProgressBar currentStep={'Location'} />
      <Container>
        <Form>
          <Row>
            <Col className="setup-step-title mb-4">Gateway Location</Col>
          </Row>
          <ClinicDetails projectMetadata={projectMetadata} project={project}></ClinicDetails>
          <GwLocationInfo gwLocations={gwLocations} deviceId={deviceId!} />
          <Row>
            <Col className="setup-section-title font-weight-bold fs-20 mt-4">Contacts</Col>
          </Row>
          <ContactList
            contacts={projectMetadata?.contacts ?? []}
            contact={projectMetadata?.contact ?? projectContactInitValue()}
          ></ContactList>
          <Row className="mt-4 mb-5 text-center">
            <Col>
              <Button
                className="outlined-btn me-3"
                type="button"
                onClick={() =>
                  navigate(
                    routesConstant.gwSetupSetLocation.relatirve({
                      companyId: companyId!,
                      projectId: projectId!,
                      deviceId: deviceId!,
                    })
                  )
                }
              >
                Edit
              </Button>
              <AllocateButton />
            </Col>
          </Row>
        </Form>
      </Container>
    </>
  );
};
