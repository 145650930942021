import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { loginTheUser } from './userSlice';

export interface SpinnerState {
  show: boolean;
}

const initialState: SpinnerState = {
  show: false,
};

export const runWithSpinner = createAsyncThunk<void, () => Promise<void>>('spinner/run', async (method) => {
  try {
    await method();
  } finally {
  }
});

export const spinnerSlice = createSlice({
  initialState,
  name: 'spinner',
  reducers: {
    showSpinner: (state, action: PayloadAction<boolean>) => {
      return { ...state, show: action.payload };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginTheUser.pending, (state) => {
        state.show = true;
      })
      .addCase(loginTheUser.rejected, (state) => {
        state.show = false;
      })
      .addCase(loginTheUser.fulfilled, (state) => {
        state.show = false;
      })
      .addCase(runWithSpinner.pending, (state) => {
        state.show = true;
      })
      .addCase(runWithSpinner.rejected, (state, action) => {
        console.log(action.error);
        state.show = false;
      })
      .addCase(runWithSpinner.fulfilled, (state) => {
        state.show = false;
      });
  },
});

export const { showSpinner } = spinnerSlice.actions;

export const selectSpinnerState = (state: RootState) => state.spinner;

export default spinnerSlice.reducer;
